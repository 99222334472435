import React from "react";
import "./css/footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer
      className="bg-primary text-white py-8 px-4 lg:px-12 lg:py-10"
      style={{
        backgroundImage: "url('/icons/footer/logo.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="flex justify-between">
        {/* About Section */}
        <div className="max-w-[300px]">
          <h3 className="text-ylo text-3xl font-bold mb-6">About A.C.T.</h3>
          <p className="text-xl">
            Offering streamlined charity fund management solutions to support
            and empower both fundraisers and donors on their path.
          </p>
        </div>

        {/* Contact Section */}
        <div className="flex flex-col gap-5 min-w-[340px]">
          <h3 className="text-ylo text-3xl font-bold">
            For Any Queries Call Us
          </h3>
          <ul className="text-lg text-white space-y-6">
            <li className="flex items-center">
              <img className="mr-2" src="/icons/footer/Mail.png" alt="mail" />
              office@actfund.org.uk
            </li>
            <li className="flex items-center">
              <img className="mr-2" src="/icons/footer/Phone.png" alt="mail" />
              0333 613 4528
            </li>
            <li className="flex items-center">
              <img
                className="mr-2"
                src="/icons/footer/Location.png"
                alt="mail"
              />
              Suite 1, 35 Northumberland St. Salford M7 4DR
            </li>
          </ul>
        </div>

        {/* Page Links Section */}
        <div>
          <h3 className="text-ylo text-3xl font-bold mb-5">Page Links</h3>
          <ul className="text-lg text-white space-y-6">
            <Link to="/about-us">
              {" "}
              <li>About Us</li>
            </Link>
            <Link to="/contact-us">
              <li>Contact Us</li>
            </Link>
          </ul>
        </div>

        {/* Opening Hours Section */}
        <div>
          <h3 className="text-ylo text-3xl font-bold mb-5">Opening Hours</h3>
          <p className="text-lg text-white">Sun - Thu: 01:00 - 05:00</p>
        </div>
      </div>

      <div className="mt-10 flex justify-between items-center border-t border-borderColor2 pt-2 text-sm text-white text-center md:text-left">
        <div className="flex space-x-2">
          <img src="/icons/footer/card.png" alt="UPI" className="h-9" />
        </div>
        <p>
          {" "}
          Privacy Policy | Term of use © {new Date().getFullYear()} ACT. All
          rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
