import React from "react";
import RequestForm from "../components/features/RequestForAccount/RequestForm";

const RequestForAccount = () => {
  return (
    <>
      <RequestForm />
    </>
  );
};

export default RequestForAccount;
