import React from "react";

const Info = () => {
  return (
    <section className="h-[60vh] flex text-center">
      <div className="flex-1 flex justify-center text-white items-center bg-dPrimary">
        <p className="w-10/12 text-2xl">
          With a global network managing over thousands of accounts, ACT
          continuously invests in advanced technology and industry-best
          practices. Our goal is to make the process of giving as seamless and
          effective as possible, ensuring that help reaches those in need
          quickly, reliably, and with dignity.
        </p>
      </div>
      <div className="flex-1 bg-basee text-primary flex justify-center items-center">
        <p className="w-10/12 text-2xl">
          For donors, ACT provides more than just a platform—it's a promise of
          trust and confidence. Every cause on our site is thoroughly reviewed,
          ensuring that your donations support only the most reputable and
          compliant organisations.
        </p>
      </div>
    </section>
  );
};

export default Info;
