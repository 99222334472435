// import React from "react";

// const Header = () => {
//   return (
//     <nav className="px-24 h-20 mt-8 relative z-50">
//       <div className="bg-white rounded-[40px] px-5 flex justify-between items-center">
//         <img src="/logo.png" alt="act" className="h-full w-40" />
//         <ul className="flex font-bold gap-11 text-secondary">
//           <li>Home</li>
//           <li>About us</li>
//           <li>Open an account</li>
//           <li>Contact us</li>
//         </ul>
//         <button
//           className="py-2 px-5 rounded-[20px] flex text-xl gap-2 items-center font-semibold text-white"
//           style={{
//             background:
//               "linear-gradient(108deg, #2A3577 43.58%, #6ECAC9 105.3%)",
//           }}
//         >
//           Login <img src="/icons/SortDown.png" alt="" />
//         </button>
//       </div>
//     </nav>
//   );
// };

// export default Header;

import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.pathname);
  return (
    <nav className="px-24 h-20 mt-8 relative z-50">
      <div
        className={`${
          location.pathname === "/contact-form" ||
          location.pathname === "/contact-us"
            ? "bg-[#D9D9D9]"
            : "bg-white"
        } rounded-[40px] px-5 flex justify-between items-center`}
      >
        <Link to="/">
          <img src="/logo.png" alt="act" className="h-full w-40" />
        </Link>
        <ul className="flex font-bold gap-11 text-secondary">
          {[
            { label: "Home", to: "/" },
            { label: "About us", to: "/about-us" },
            { label: "Open an account", to: "/contact-form" },
            { label: "Contact us", to: "/contact-us" },
          ].map((item) => (
            <li key={item.label} className="relative group">
              <Link
                to={item.to}
                className="relative pb-2 hover:text-primary transition-colors duration-200"
              >
                {item.label}
                {/* Underline effect */}
                <span className="absolute left-0 bottom-0 w-full h-[2px] bg-secondary scale-x-0 origin-left transition-transform duration-300 ease-in-out group-hover:scale-x-100"></span>
              </Link>
            </li>
          ))}
        </ul>

        <button
          onClick={() => navigate("/login")}
          className="py-2 px-5 rounded-[20px] flex text-xl gap-2 items-center font-semibold text-white"
          style={{
            background:
              "linear-gradient(108deg, #2A3577 43.58%, #6ECAC9 105.3%)",
          }}
        >
          Login <img src="/icons/SortDown.png" alt="" />
        </button>
      </div>
    </nav>
  );
};

export default Header;
