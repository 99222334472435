import React from "react";

const ExploreButton = ({
  type,
  label,
  onClick,
  backgroundColor,
  textColor = "text-primary",
  disabled = false,
  extraClass,
}) => {
  return (
    <button
      type={type}
      className={`${extraClass} px-4 gap-2 flex justify-center items-center h-7 text-2xl font-bold rounded-3xl ${backgroundColor} ${textColor} hover:scale-105 active:scale-95 transition-transform duration-200 ease-in-out`}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
      <div className="w-7 h-7 rounded-full bg-lGree flex justify-center items-center">
        <img src="/icons/DownArrow.png" alt="" />
      </div>
    </button>
  );
};

export default ExploreButton;
