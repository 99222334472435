// src/components/DocumentPage.js
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useDownloadFileQuery,
  useGetAllFilesQuery,
  useUploadDocMutation,
} from "../store/apis/documentApi";
import { toast } from "react-toastify";
import Loader from "../components/shared/Loader";
import NoRecordFound from "../components/shared/NoRecordFound";
import formatDate from "../utils/formateDate";

const MyDocuments = () => {
  const [dataId, setDataId] = useState(null);
  const [file, setFile] = useState(null);
  const [tryUload, setTryUpload] = useState(false);

  const selectedClient = useSelector((state) => state.user.selectedClient);
  const client = useSelector((state) => state.user.clients[selectedClient]);
  const token = useSelector((state) => state.user.token);

  const { data: documents, isLoading } = useGetAllFilesQuery({
    token,
    clientID: client.ID,
  });
  const [uploadDoc] = useUploadDocMutation();
  const {
    data: downloadedFile,
    isLoading: downloadedLoading,
    isSuccess,
  } = useDownloadFileQuery(
    { id: dataId, token, clientID: client.ID },
    { skip: !dataId }
  );

  useEffect(() => {
    if (isSuccess && downloadedFile) {
      const link = document.createElement("a");
      link.href = downloadedFile.downloadUrl;
      link.download = downloadedFile.fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // Reset dataId to null after download
      setDataId(null);
    }
  }, [isSuccess, downloadedFile, dataId]);

  const handleDownload = (id) => {
    setDataId(id);
  };

  if (isLoading || downloadedLoading) {
    return <Loader />;
  }

  const handleFileUpload = (uploadedFile) => {
    setFile(uploadedFile);
  };

  const handleFileChange = (event) => {
    setTryUpload(true);
    const file = event.target.files[0];
    if (file) {
      handleFileUpload(file);
    }
  };

  const handleStoreFile = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("permission", "Owner");

      try {
        await uploadDoc({ fileData: formData, token, clientID: client.ID });
        toast.success("File uploaded successfully.");
        setFile(null);
      } catch (err) {
        toast.error("There was an server side error!");
      }
    } else {
      toast.error("No file selected for upload!");
    }
  };

  return (
    <section>
      <h1 className="text-white text-5xl font-bold mb-5">
        Document management
      </h1>

      <div className="space-y-7">
        {/* Upload Section */}
        <div className="flex w-full bg-white h-[78px] px-4 rounded justify-between items-center">
          {/* <div className="w-full flex"> */}
          <div className="min-w-[146px] space-y-2">
            <p className="text-primary text-lg font-bold">Upload docs</p>
            <p className="text-[#252525] text-sm">(CSV, PDF, or JPEG)</p>
          </div>
          <div className="w-full max-w-[489px]">
            <label
              htmlFor="file-upload"
              className="flex gap-2 px-7 text-[#888888] w-full items-center border border-gray-400 rounded-[25px] py-3 cursor-pointer hover:border-gray-[#888] h-[51px]"
            >
              <img src="/icons/document/Upload.png" alt="" />
              <p>Upload docs</p>
            </label>
            <input
              id="file-upload"
              type="file"
              className="hidden"
              accept=".csv,application/pdf, image/jpeg"
              onChange={handleFileChange}
            />
          </div>
          {/* </div> */}
          <button
            className="bg-primary text-xl font-bold w-[143px] h-[51px] text-white rounded shadow-lg hover:bg-[#303F9F]"
            onClick={handleStoreFile}
          >
            Save
          </button>
        </div>

        {/* Uploaded Documents */}
        {/* Permission Filter */}
        <div className="bg-white rounded mb-4">
          <div className="flex justify-between px-4 py-6 items-center">
            <h2 className="font-bold text-primary">Uploaded Documents</h2>
            <span className="text-[#888888]">17 Documents</span>
            <div className="flex items-center gap-2">
              <div className="relative h-10">
                <input
                  type="text"
                  placeholder="Search"
                  className="w-[242px] rounded-[20px] border h-10 border-gray-300 bg-[#DEDEDE] text-[#888888] pr-3 pl-8"
                />
                <img
                  src="/icons/document/Search.png"
                  alt="search"
                  className="absolute cursor-pointer left-3 top-1/2 -translate-y-1/2"
                />
              </div>
              <button className="text-sm w-[84px] h-9 text-[#888888] rounded-[20px] shadow-lg hover:bg-primary transform duration-200 ease-in-out border border-[#888888]">
                File type
              </button>
            </div>
          </div>
          <div className="border-t border-gray-200">
            <div className="flex justify-between p-4 items-center ">
              <label className="font-bold text-primary">Permission</label>
              <div className="flex items-center space-x-10 text-primary font-bold">
                <label className="cursor-pointer">
                  <input
                    type="radio"
                    name="permission"
                    className="mr-2"
                    checked
                  />
                  Public
                </label>
                <label className="cursor-pointer">
                  <input type="radio" name="permission" className="mr-2" />
                  Owner
                </label>
                <label className="cursor-pointer">
                  <input type="radio" name="permission" className="mr-2" />
                  Office only
                </label>
              </div>
            </div>
          </div>
        </div>

        {/* Document List */}
        <div className="overflow-auto">
          <table className="w-full text-left rounded overflow-hidden">
            {/* <thead>
              <tr>
                <th className="py-2 px-4 text-sm font-semibold">File</th>
                <th className="py-2 px-4 text-sm font-semibold">Date</th>
                <th className="py-2 px-4 text-sm font-semibold">Download</th>
              </tr>
            </thead> */}
            <tbody>
              {documents?.files?.length ? (
                documents?.files.map((doc, index) => (
                  <tr
                    key={Math.random()}
                    className={`text-[#252525] ${
                      index % 2 === 0 ? "bg-gray-100" : "bg-white"
                    } hover:bg-gray-200`}
                  >
                    <td className="py-4 px-5">
                      <img src="/icons/document/pdf.png" alt="" />
                    </td>
                    <td className="">
                      <span className="truncate">{doc.fileName}</span>
                    </td>
                    <td className="text-sm text-gray-600">
                      {formatDate(doc.dateAdded)}
                    </td>
                    <td className="px-5 flex py-4 justify-end items-center">
                      <button
                        className="text-blue-500 hover:text-blue-700"
                        onClick={() => handleDownload(doc.id)}
                      >
                        <img
                          src="/icons/document/Download.png"
                          alt="download"
                        />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <NoRecordFound />
              )}
            </tbody>
          </table>
        </div>
        <div className="w-full flex justify-end">
          <div className="flex items-center gap-1 px-5 text-white">
            <img
              src="/icons/document/backward.png"
              alt=""
              className="w-3 h-3 cursor-pointer"
            />
            <div>
              <p>1 - 17</p>
            </div>
            <img
              src="/icons/document/Forward.png"
              alt=""
              className="w-3 h-3 cursor-pointer"
            />
          </div>
        </div>
      </div>
    </section>
    // <Container maxWidth="md" className="document-page-container">
    //   <Typography variant="h4" className="page-title">
    //     Document Management
    //   </Typography>
    //   <Grid container spacing={3}>
    //     <Grid item xs={12}>
    //       <Paper className="paper-container">
    //         <FileUploader
    //           onFileUpload={handleFileUpload}
    //           handleStoreFile={handleStoreFile}
    //           setTryUpload={setTryUpload}
    //         />
    //       </Paper>
    //     </Grid>
    //     <Grid item xs={12}>
    //       <Paper className="paper-container">
    //         <DocumentViewer file={file} tryUload={tryUload} />
    //       </Paper>
    //     </Grid>
    //     <Grid item xs={12}>
    //       <Paper className="paper-container">
    //         <DocumentList />
    //       </Paper>
    //     </Grid>
    //   </Grid>
    // </Container>
  );
};

export default MyDocuments;
