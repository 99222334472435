import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSignupByPasswordMutation } from "../store";
import { toast } from "react-toastify";
import { sinupSchema } from "../Validations/SignupValidations";
import { useFormik } from "formik";

const Signup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [SignupByPassword, SignupByPasswordResult] =
    useSignupByPasswordMutation();
  // Handlers
  const onSubmit = useCallback(
    async ({ email, password }, actions) => {
      const res = await SignupByPassword({ email, password });
      if (res.error) {
        toast.error("Failed to signup: " + res.error.data.message);
      } else {
        toast.success(
          "Please check your email to complete the registration process"
        );
      }
      actions.resetForm();
    },
    [SignupByPassword]
  );

  const handleClickShowPassword = useCallback(
    () => setShowPassword((show) => !show),
    []
  );
  const handleMouseDownPassword = useCallback(
    (event) => event.preventDefault(),
    []
  );

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: sinupSchema,
    onSubmit,
  });
  return (
    <section className="flex justify-center items-center">
      <div
        className="flex items-center w-10/12 justify-center min-h-screen mt-20"
        style={{
          backgroundImage: "url('/bglogo.png')",
          // backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="w-full max-w-xl px-8 py-10 rounded-lg relative text-center">
          <h1 className="text-5xl font-bold text-lGree mb-5">
            Create your account
          </h1>
          <p className="text-[#1B1B1B] text-2xl mb-9">welcome back</p>

          {/* Google Login Button */}
          <button className="w-full bg-[#F1F1F1] rounded-[14px] flex items-center justify-center py-3 border border-[#B1B1B1] text-[#1B1B1B] hover:bg-gray-100">
            <img
              src="/icons/Google.png"
              alt="google"
              className="mr-2 text-xl"
            />
            Continue with Google
          </button>

          <div className="my-9 flex justify-center items-center gap-2">
            <div className="w-16 bg-[#B1B1B1] h-[1px]"></div>
            <p className="text-[#1B1B1B] text-lg">or</p>
            <div className="w-16 bg-[#B1B1B1] h-[1px]"></div>
          </div>

          {/* Name Input */}
          <div className="mb-9">
            <input
              type="text"
              placeholder="Name"
              className="w-full px-4 py-3 border border-[#B1B1B1] rounded-[14px] focus:outline-none focus:ring-2 focus:ring-[#1F2977] text-[#1F2977]"
            />
          </div>
          {/* Email Input */}
          <div className="mb-9">
            <input
              type="email"
              placeholder="Email"
              className="w-full px-4 py-3 border border-[#B1B1B1] rounded-[14px] focus:outline-none focus:ring-2 focus:ring-[#1F2977] text-[#1F2977]"
            />
          </div>

          {/* Password Input */}
          <div className="mb-9">
            <input
              type="password"
              placeholder="Password"
              className="w-full px-4 py-3 border border-[#B1B1B1] rounded-[14px] focus:outline-none focus:ring-2 focus:ring-[#1F2977]"
            />
          </div>
          {/* Password Input */}
          <div className="mb-5">
            <input
              type="password"
              placeholder="Re-enter password"
              className="w-full px-4 py-3 border border-[#B1B1B1] rounded-[14px] focus:outline-none focus:ring-2 focus:ring-[#1F2977]"
            />
          </div>

          {/* Forgot Password */}
          <div className="flex items-center mb-5">
            <input
              type="checkbox"
              id="agreement"
              className="w-4 h-4 cursor-pointer text-[#1F2977] border border-gray-300 rounded focus:ring-0"
            />
            <label htmlFor="agreement" className="ml-2 text-[#B1B1B1] text-sm">
              I accept all terms & condition
            </label>
          </div>

          {/* Login Button */}
          <button className="w-full py-3 rounded-[27px] bg-lGree text-white text-lg font-semibold hover:bg-lGree">
            Signup
          </button>

          {/* Sign Up Link */}
          <p className="mt-4 text-gray-500 text-sm">
            Already have an account?{" "}
            <Link
              to="/login"
              className="text-[#1F2977] font-medium hover:underline"
            >
              Log in
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Signup;
