const PrimaryInput = ({
  label,
  type = "text",
  name,
  placeholder,
  handleChange,
  handleBlur,
  value,
}) => {
  return (
    <div className="space-y-1">
      <label className="text-sm" htmlFor={name}>
        {label}
      </label>
      <input
        value={value}
        onBlur={handleBlur}
        onChange={handleChange}
        type={type}
        id={name}
        name={name}
        placeholder={placeholder}
        className="w-full p-3 border border-white bg-transparent rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
      />
    </div>
  );
};

export default PrimaryInput;
