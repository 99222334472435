import React, { useEffect } from "react";
import PrimaryInput from "../components/inputfield/PrimaryInput";
import { useFormik } from "formik";
import { supportMessageSchema } from "../Validations/contactFormValidations";
import { useContactWithSupportMutation } from "../store/apis/supportAPI";
import { toast } from "react-toastify";

const ContactUs = () => {
  const [contactWithSupport, ContactWithSupportResult] =
    useContactWithSupportMutation();

  //handlers
  const onSubmit = async ({ name, email, subject, message }, actions) => {
    const data = {
      name,
      email,
      subject,
      message,
    };
    await contactWithSupport(data);
    // const res = await contactWithSupport(data);
    // if (res.error) {
    //   toast.error("Faild to send: " + res.error.data.message);
    // }
    actions.resetForm();
    toast.success(
      "We received your email. We will get in touch with you soon."
    );
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
    validationSchema: supportMessageSchema,
    onSubmit,
  });

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  // if (ContactWithSupportResult.isSuccess) {
  //   return (
  //     <div>Please check your email to complete the registration proccess</div>
  //   );
  // }

  return (
    <section className="bg-blue-50 py-12 px-6 h-[120vh] flex justify-center items-center">
      <div className="w-full grid grid-cols-1 md:grid-cols-2 items-center">
        {/* Left Section */}
        <div className="text-left px-12 text-primary bg-lightB h-full flex flex-col justify-around py-10 lg:py-20">
          <div className="space-y-1">
            <h2 className="text-4xl font-bold">Get in touch with us</h2>
            <p className="text-2xl">
              Get in touch with us, our team is ready to assist you.
            </p>
          </div>
          <div className="space-y-3">
            <div className="space-y-1">
              <h2 className="text-4xl font-bold">Email us</h2>
              <p className="text-xs font-normal">Email our team today</p>
            </div>
            <p className="text-2xl">office@actfund.org.uk</p>
          </div>
          <div className="space-y-1">
            <div className="space-y-1">
              <h2 className="text-4xl font-bold">Call us</h2>
              <p className="text-xs font-normal">Call our team today.</p>
            </div>
            <p className="text-2xl">0333-613-4528</p>
          </div>
        </div>

        {/* Right Section */}
        <div className="bg-primary text-white p-8 ">
          <h3 className="text-4xl font-bold mb-8">Submit a request</h3>
          <form onSubmit={handleSubmit} className="flex flex-col gap-6">
            <div>
              <PrimaryInput
                label="Name"
                name="name"
                placeholder="First Name"
                handleBlur={handleBlur}
                handleChange={handleChange}
                value={values.name}
              />
              {touched.name && errors.name && (
                <p className="text-red-500 text-sm">{errors.name}</p>
              )}
            </div>

            <div>
              <PrimaryInput
                label="Your Email Address"
                type="email"
                placeholder="Email Address"
                handleBlur={handleBlur}
                handleChange={handleChange}
                value={values.email}
                name="email"
              />
              {touched.email && errors.email && (
                <p className="text-red-500 text-sm">{errors.email}</p>
              )}
            </div>

            <div>
              <PrimaryInput
                label="Subject"
                type="text"
                placeholder="Subject"
                handleBlur={handleBlur}
                handleChange={handleChange}
                value={values.subject}
                name="subject"
              />
              {touched.subject && errors.subject && (
                <p className="text-red-500 text-sm">{errors.subject}</p>
              )}
            </div>

            <div className="space-y-1">
              <label className="block text-white text-sm " htmlFor="message">
                Message
              </label>
              <textarea
                onBlur={handleBlur}
                onChange={handleChange}
                id="message"
                value={values.message}
                name="message"
                rows="5"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-white bg-transparent text-white"
              ></textarea>
              {touched.message && errors.message && (
                <p className="text-red-500 text-sm">{errors.message}</p>
              )}
            </div>

            <div className="flex items-center mb-4">
              <input
                type="checkbox"
                id="agreement"
                className="w-4 h-4 text-[#1F2977] border border-gray-300 rounded focus:ring-0"
              />
              <label htmlFor="agreement" className="ml-2 text-white text-sm">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </label>
            </div>

            <button
              type="submit"
              className="w-full py-3 text-xl bg-white text-primary font-bold rounded hover:bg-gray-200 hover:scale-105 active:scale-95 transition"
              disabled={isSubmitting}
            >
              Share your thoughts
            </button>
          </form>
        </div>
        {/* <div className="bg-primary text-white p-8 ">
          <h3 className="text-4xl font-bold mb-8">Submit a request</h3>
          <form onSubmit={handleSubmit} className="flex flex-col gap-10">
            <PrimaryInput
              label="Name"
              name="name"
              placeholder="First Name"
              handleBlur={handleBlur}
              handleChange={handleChange}
              value={values.name}
            />
            <PrimaryInput
              label="Your Email Address"
              type="email"
              placeholder="Email Address"
              handleBlur={handleBlur}
              handleChange={handleChange}
              value={values.email}
              name="email"
            />
            <PrimaryInput
              label="Subject"
              type="text"
              placeholder="Subject"
              handleBlur={handleBlur}
              handleChange={handleChange}
              value={values.subject}
              name="subject"
            />
            <div className="space-y-1">
              <label className="block text-white text-sm " htmlFor="message">
                Message
              </label>
              <textarea
                onBlur={handleBlur}
                onChange={handleChange}
                id="message"
                value={values.message}
                name="message"
                rows="5"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-white bg-transparent text-white"
              ></textarea>
            </div>

            <div className="flex items-center mb-4">
              <input
                type="checkbox"
                id="agreement"
                className="w-4 h-4 text-[#1F2977] border border-gray-300 rounded focus:ring-0"
              />
              <label htmlFor="agreement" className="ml-2 text-white text-sm">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </label>
            </div>
            <button
              type="submit"
              className="w-full py-3 text-xl bg-white text-primary font-bold rounded hover:bg-gray-200 hover:scale-105 active:scale-95 transition"
            >
              Share your thoughts
            </button>
          </form>
        </div> */}
      </div>
    </section>
  );
};

export default ContactUs;
