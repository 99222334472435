import { useGoogleLogin } from "@react-oauth/google";
import React from "react";
import { successLogin, useLoginByGoogleMutation } from "../../store";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../shared/Loader";

const GoogleLoginBtn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [LoginByGoogle, LoginByGoogleResult] = useLoginByGoogleMutation();

  const handleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      const res = await LoginByGoogle(response?.access_token);
      if (res.data) {
        const payload = {
          token: res.data?.data?.token?.token,
          expiresIn: res.data?.data?.token?.expiresIn,
          user: res.data?.data?.user,
        };
        dispatch(successLogin(payload));
        toast.success("You have logged in successfully");
        navigate("/account");
      } else {
        toast.error(res.error.data.message);
      }
    },
    onError: () => {
      toast.error("Login Failed");
    },
  });
  if (LoginByGoogleResult.isLoading) return <Loader />;
  return (
    <button
      onClick={handleLogin}
      className="w-full bg-[#F1F1F1] rounded-[14px] flex items-center justify-center py-3 border border-[#B1B1B1] text-[#1B1B1B] hover:bg-gray-100"
    >
      <img src="/icons/Google.png" alt="google" className="mr-2 text-xl" />
      Continue with Google
    </button>
  );
};

export default GoogleLoginBtn;

// import { GoogleLogin } from "@react-oauth/google";
// import { useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { toast } from "react-toastify";

// import '../css/GoogleLoginBtn.css';
// import { successLogin, useLoginByGoogleMutation } from "../../store";
// import Loader from "../shared/Loader";

// function GoogleLoginBtn() {

//   const [LoginByGoogle, LoginByGoogleResult] = useLoginByGoogleMutation();
//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   //GOOGLE
//   const handleSuccessGoogleLogin = async (response) => {
//     const { credential } = response;

//     const res = await LoginByGoogle(credential);
//     if (res.data) {
//       const payload = {
//         token: res.data?.data?.token?.token,
//         expiresIn: res.data?.data?.token?.expiresIn,
//         user: res.data?.data?.user
//       }
//       dispatch(successLogin(payload));
//       toast.success("You have logged in successfully");
//       navigate("/account");
//     } else {
//       toast.error(res.error.data.message);
//     }
//   };

//   const handleFailureGoogleLogin = (error) => {
//     toast.error("Google sign-in failed:", error);
//   };

//   if (LoginByGoogleResult.isLoading) return <Loader />;

//   return (
//     <div className="google-login-btn-container">
//       <GoogleLogin
//         onSuccess={handleSuccessGoogleLogin}
//         onFailure={handleFailureGoogleLogin}
//         render={(renderProps) => (
//           <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="google-login-custom-btn">
//             <img src="path-to-google-icon.png" alt="Google icon" />
//             Continue with Google
//           </button>
//         )}
//       />

//     </div>
//   );
// }

// export default GoogleLoginBtn;
