import React from "react";

const AboutUsHero = () => {
  return (
    <section className="h-screen relative bg-primary">
      <div className="flex justify-center items-center h-full relative z-20">
        <div className="max-w-[745px] text-center space-y-5">
          <h2 className="text-nutural font-bold text-5xl">
            Leading the Way{" "}
            <span className="text-white">in Charitable Solutions</span>
          </h2>
          <p className="text-white text-xl font-bold">
            ACT is a pioneer in providing innovative services to organisations
            and individuals, focused on streamlining the management and
            distribution of charitable funds. Our platform empowers both donors
            and fundraisers, offering a smooth and transformative fundraising
            experience.
          </p>
        </div>
      </div>
      <div className="w-full h-full absolute bg-dPrimary top-0 left-0 opacity-80 z-10"></div>
      <img
        src="/icons/line.png"
        alt="line"
        className="absolute bottom-28 left-0"
      />
    </section>
  );
};

export default AboutUsHero;
