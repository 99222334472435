import React from "react";
import AboutUsHero from "../components/features/AboutUs/AboutUsHero";
import Info from "../components/features/AboutUs/Info";
import ACTPrinciples from "../components/features/AboutUs/ACTPrinciples";

const AboutUs = () => {
  return (
    <main>
      <AboutUsHero />
      <Info />
      <ACTPrinciples />
    </main>
  );
};

export default AboutUs;
