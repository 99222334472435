import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useFetchUsersClientsQuery, setUsersClients } from "../store";
import ClientsDetails from "../components/features/Dashboard/ClientsDetails";
import TransactionsTable from "../components/features/Dashboard/TransactionsTable";
import Loader from "../components/shared/Loader";
import Users from "../components/Users";

function DashboardPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  const clients = useSelector((state) => state.user.clients);

  const { isError, isSuccess, data, error, isLoading, refetch } =
    useFetchUsersClientsQuery(token, {
      skip: !token,
    });

  useEffect(() => {
    // Redirect to login if not signed in
    if (!token) {
      navigate("/login");
    }
  }, [token, navigate]);

  useEffect(() => {
    if (isSuccess && data?.data) {
      dispatch(setUsersClients(data.data));
    }
  }, [dispatch, isSuccess, data]);

  // Refetch clients data whenever the component is mounted
  useEffect(() => {
    if (token) {
      refetch();
    }
  }, [token, refetch]);

  if (isLoading || !clients.length) {
    return <Loader />;
  } else {
    return (
      <div className="min-h-screen">
        <ClientsDetails />
        <TransactionsTable />
        {/* <Users /> */}
      </div>
    );
  }
}

export default DashboardPage;
