import React from "react";

const SendRequest = () => {
  return (
    <div>
      <h1>This is Send request page!</h1>
    </div>
  );
};

export default SendRequest;
