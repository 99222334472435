const ACTPrinciples = () => {
  return (
    <section className="bg-white py-12 lg:py-16 px-6 lg:px-24">
      <div className="text-center mb-12 space-y-4">
        <h2 className="text-6xl font-bold text-primary">ACT Principles</h2>
        <p className="text-3xl text-secondary">
          Focused on{" "}
          <span className="text-primary font-bold">
            Trusted, Seamless Donations.
          </span>
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Card 1 */}
        <div className="bg-dPrimary flex flex-col justify-center items-center text-white h-[432px] p-6 rounded-lg text-center">
          <div className="mb-7">
            <img src="/icons/aboutus/responsible.png" alt="skills" />
          </div>
          <h3 className="text-2xl font-bold mb-6">Responsibility</h3>
          <p>
            We understand that every donation is meaningful. Our users rely on
            us to manage their funds with care and confidentiality, and we are
            fully committed to ensuring that trust is maintained.
          </p>
        </div>

        {/* Card 2 */}
        <div className="bg-dPrimary flex flex-col justify-center items-center text-white h-[432px] p-6 rounded-lg text-center">
          <div className="mb-7">
            {/* Add your icon here */}
            <img src="/icons/aboutus/Trust.png" alt="skills" />
          </div>
          <h3 className="text-2xl font-bold mb-6">Respect</h3>
          <p>
            Our clients' needs and well-being are our top priority. At ACT,
            every user is treated with respect, ensuring that they feel valued
            and supported throughout their journey with us.
          </p>
        </div>

        {/* Card 3 */}
        <div className="bg-dPrimary flex flex-col justify-center items-center text-white h-[432px] p-6 rounded-lg text-center">
          <div className="mb-7">
            {/* Add your icon here */}
            <img src="/icons/aboutus/Skills.png" alt="skills" />
          </div>
          <h3 className="text-2xl font-bold mb-6">Efficiency</h3>
          <p>
            Clients trust us to handle every transaction quickly and accurately.
            This dedication to efficiency is at the core of our service,
            ensuring timely support for all charitable efforts.
          </p>
        </div>
      </div>
    </section>
  );
};

export default ACTPrinciples;
