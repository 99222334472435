import React, { useState } from "react";
import { Link } from "react-router-dom";

const sidebarItems = [
  { label: "Dashboard", to: "/", icon: "/icons/sidebar/Laptop.png" },
  {
    label: "Request a payout",
    to: "/about-us",
    icon: "/icons/sidebar/Pound.png",
  },
  {
    label: "Donate to a charity",
    to: "/contact-form",
    icon: "/icons/sidebar/Heart.png",
  },
  {
    label: "Internal transfer fund",
    to: "/contact-us",
    icon: "/icons/sidebar/Arrow.png",
  },
  {
    label: "My Documents",
    to: "/contact-us",
    icon: "/icons/sidebar/Documents.png",
  },
  {
    label: "Recurring",
    to: "/contact-us",
    icon: "/icons/sidebar/Appointment.png",
  },
  {
    label: "Send a request",
    to: "/contact-us",
    icon: "/icons/sidebar/Comment.png",
  },
  {
    label: "My Account Details",
    to: "/contact-us",
    icon: "/icons/sidebar/Account.png",
  },
];

const Test = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Toggle Sidebar visibility
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  return (
    <div className="min-h-screen flex mt-40">
      {/* Sidebar */}
      <aside
        className={`fixed top-0 left-0 z-40 h-full w-64 xl:w-[420px] bg-white border-r border-gray-200 transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform md:translate-x-0`}
      >
        <div className="px-4 py-5">
          <img src="/logo.png" alt="Logo" className="w-32" />
        </div>
        <nav className="mt-6">
          <ul className="">
            {sidebarItems.map((item) => (
              <li>
                <Link
                  to={item.to}
                  className="flex gap-6 items-center px-4 py-5 bg-lightB text-[#888888]"
                >
                  <img src={item.icon} alt="icon" />
                  <span>{item.label}</span>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </aside>

      {/* Overlay for Sidebar on Mobile */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 z-30 bg-black bg-opacity-50 md:hidden"
          onClick={toggleSidebar}
        ></div>
      )}

      {/* Main Content */}
      <div className="flex-1 md:ml-64 xl:ml-[420px] bg-primary">
        {/* Header with Hamburger Menu */}
        <header className="flex items-center justify-between mb-6">
          <button
            onClick={toggleSidebar}
            className="md:hidden text-2xl text-blue-800"
          >
            {isSidebarOpen ? <p>Open</p> : <p>Close</p>}
          </button>
          <h1 className="text-2xl font-bold text-blue-800">Welcome Back</h1>
          <p className="text-gray-600">Hey Lily!</p>
        </header>

        {/* Account Info Cards */}
        {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
          <div className="bg-white p-4 rounded shadow text-center">
            <h2 className="text-lg font-semibold">ACT Account ID</h2>
            <p className="text-2xl font-bold text-blue-800">5543</p>
          </div>
          <div className="bg-white p-4 rounded shadow text-center">
            <h2 className="text-lg font-semibold">Your Account Balance</h2>
            <p className="text-2xl font-bold text-blue-800">£ 905.00</p>
          </div>
        </div> */}

        {/* Table */}
        {/* <div className="overflow-auto rounded shadow">
          <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-6 py-4 font-medium text-gray-900">Date</th>
                <th className="px-6 py-4 font-medium text-gray-900">
                  Transaction Type
                </th>
                <th className="px-6 py-4 font-medium text-gray-900">Amount</th>
                <th className="px-6 py-4 font-medium text-gray-900">
                  Money Type
                </th>
                <th className="px-6 py-4 font-medium text-gray-900">Fee</th>
                <th className="px-6 py-4 font-medium text-gray-900">Total</th>
                <th className="px-6 py-4 font-medium text-gray-900">Note</th>
                <th className="px-6 py-4 font-medium text-gray-900">Actions</th>
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: 15 }).map((_, index) => (
                <tr key={index} className="border-t">
                  <td className="px-6 py-4">24 Sep, 2024</td>
                  <td className="px-6 py-4">Pending/Paid Out</td>
                  <td className="px-6 py-4">£ 0</td>
                  <td className="px-6 py-4">Bank</td>
                  <td className="px-6 py-4">£ 0</td>
                  <td className="px-6 py-4">£ 0</td>
                  <td className="px-6 py-4">7</td>
                  <td className="px-6 py-4">
                    <button className="p-2 text-blue-600 hover:underline">
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div> */}
      </div>
    </div>
  );
};

export default Test;
