import { Outlet } from "react-router-dom";
import Footer from "../shared/Footer";
import Header from "../shared/Header";

export default function Root() {
  return (
    <section style={{ margin: "0", padding: "0" }}>
      <Header />
      <main className="-mt-28">
        <Outlet />
      </main>
      <Footer />
    </section>
  );
}
