import React, { useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import "./layout.css";
import { persistor, successLogout } from "../../store";
import { useDispatch } from "react-redux";
import { googleLogout } from "@react-oauth/google";

const sidebarItems = [
  { label: "Dashboard", to: "/account", icon: "/icons/sidebar/Laptop.png" },
  {
    label: "Request a payout",
    to: "/account/request-topup",
    icon: "/icons/sidebar/Pound.png",
  },
  {
    label: "Donate to a charity",
    to: "/account/internal-charity",
    icon: "/icons/sidebar/Heart.png",
  },
  {
    label: "Internal transfer fund",
    to: "/account/internal-individual",
    icon: "/icons/sidebar/Arrow.png",
  },
  {
    label: "My Documents",
    to: "/account/my-documents",
    icon: "/icons/sidebar/Documents.png",
  },
  {
    label: "Recurring",
    to: "/account/recurring-donations",
    icon: "/icons/sidebar/Appointment.png",
  },
  {
    label: "Send a request",
    to: "/account/send-request",
    icon: "/icons/sidebar/Comment.png",
  },
  {
    label: "My Account Details",
    to: "/account/account-details",
    icon: "/icons/sidebar/Account.png",
  },
];

const AccountLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // Toggle Sidebar visibility
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const handleLogout = async () => {
    // googleLogout();
    persistor.purge();
    dispatch(successLogout());
    navigate("/");
  };

  return (
    <div className="min-h-screen flex bg-gray-100">
      {/* Sidebar */}
      <aside
        className={`fixed top-0 left-0 z-40 flex flex-col h-full w-64 xl:w-[420px] bg-white border-r border-gray-200 transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform md:translate-x-0`}
      >
        <div className="p-6">
          <Link to="/account">
            <img src="/logo.png" alt="Logo" className="w-32" />
          </Link>
        </div>
        <nav className="mt-6">
          <ul>
            {sidebarItems.map((item) => (
              <li key={Math.random()}>
                <Link
                  to={item.to}
                  className={`flex gap-6 items-center px-7 py-5 text-xl  ${
                    location.pathname === item.to
                      ? "bg-lightB text-primary font-bold"
                      : "text-[#888888] font-normal"
                  }`}
                >
                  <img src={item.icon} alt="icon" />
                  <span
                    className={`text-xl  ${
                      location.pathname === item.to
                        ? "text-primary font-bold"
                        : "text-[#888888] font-normal"
                    }`}
                  >
                    {item.label}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
        <Link
          onClick={handleLogout}
          className="flex text-xl w-full absolute bottom-0 left-0 gap-6 items-center px-7 py-5 text-[#888888]"
        >
          <img src="/icons/sidebar/Logout.png" alt="icon" />
          <span>Logout</span>
        </Link>
      </aside>

      {/* Overlay for Sidebar on Mobile */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 z-30 bg-black bg-opacity-50 md:hidden"
          onClick={toggleSidebar}
        ></div>
      )}

      {/* Main Content */}
      <div className="flex-1 md:ml-64 xl:ml-[420px] px-6 py-10 bg-primary">
        {/* Header with Hamburger Menu */}
        <header className="flex items-center md:hidden justify-between mb-6">
          <button
            onClick={toggleSidebar}
            className="md:hidden text-2xl text-blue-800"
          >
            {isSidebarOpen ? <p>Open</p> : <p>Close</p>}
          </button>
        </header>

        <Outlet />
      </div>
    </div>
  );
};

export default AccountLayout;

// import * as React from "react";
// import { styled, useTheme } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import MuiDrawer from "@mui/material/Drawer";
// import MuiAppBar from "@mui/material/AppBar";
// import Toolbar from "@mui/material/Toolbar";
// import List from "@mui/material/List";
// import CssBaseline from "@mui/material/CssBaseline";
// import Typography from "@mui/material/Typography";
// import Divider from "@mui/material/Divider";
// import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import ListItem from "@mui/material/ListItem";
// import ListItemButton from "@mui/material/ListItemButton";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import ListItemText from "@mui/material/ListItemText";
// import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { persistor, setSelectedClient, successLogout } from "../../store";
// import {
//   Button,
//   FormControl,
//   MenuItem,
//   Select,
//   useMediaQuery,
// } from "@mui/material";
// import logo from "../../assets/logo.jpg";
// import Dashboards from "@mui/icons-material/Dashboard";
// import AddCardIcon from "@mui/icons-material/AddCard";
// import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
// import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
// import ForwardIcon from "@mui/icons-material/Forward";
// import PendingIcon from "@mui/icons-material/Pending";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import Avatar from "@mui/material/Avatar";
// import Stack from "@mui/material/Stack";
// import Paper from "@mui/material/Paper";
// import "./layout.css";
// import LogoutIcon from "@mui/icons-material/Logout";

// const drawerWidth = 240;

// const openedMixin = (theme) => ({
//   width: drawerWidth,
//   transition: theme.transitions.create("width", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.enteringScreen,
//   }),
//   overflowX: "hidden",
//   position: "fixed",
//   zIndex: "1000",
// });

// const closedMixin = (theme) => ({
//   transition: theme.transitions.create("width", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   overflowX: "hidden",
//   width: `calc(${theme.spacing(7)} + 1px)`,
//   [theme.breakpoints.up("sm")]: {
//     width: `calc(${theme.spacing(8)} + 1px)`,
//   },
//   position: "fixed",
// });

// const DrawerHeader = styled("div")(({ theme }) => ({
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "flex-end",
//   padding: theme.spacing(0, 1),
//   ...theme.mixins.toolbar,
// }));

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== "open",
// })(({ theme, open }) => ({
//   zIndex: theme.zIndex.drawer + 1,
//   transition: theme.transitions.create(["width", "margin"], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     marginLeft: drawerWidth,
//     width: `calc(100% - ${drawerWidth}px)`,
//     transition: theme.transitions.create(["width", "margin"], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   }),
// }));

// const Drawer = styled(MuiDrawer, {
//   shouldForwardProp: (prop) => prop !== "open",
// })(({ theme, open }) => ({
//   width: drawerWidth,
//   flexShrink: 0,
//   whiteSpace: "nowrap",
//   boxSizing: "border-box",
//   ...(open && {
//     ...openedMixin(theme),
//     "& .MuiDrawer-paper": openedMixin(theme),
//   }),
//   ...(!open && {
//     ...closedMixin(theme),
//     "& .MuiDrawer-paper": closedMixin(theme),
//   }),
// }));

// export default function AccountLayout() {
//   const isAuth = useSelector((state) => state.user.isAuthenticated);
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const [anchorEl, setAnchorEl] = React.useState({});
//   const clients = useSelector((state) => state.user.clients);
//   const currClient = useSelector((state) => state.user.selectedClient);
//   const coilelID = useSelector((state) => state.user.coilelID);
//   const location = useLocation();

//   const handleMenu = (event, index, isSubMenu) => {
//     const key = isSubMenu ? `submenu_${index}` : index;
//     setAnchorEl({ ...anchorEl, [key]: event.currentTarget });
//   };

//   const handleLogout = async () => {
//     persistor.purge();
//     dispatch(successLogout());
//     navigate("/");
//   };

//   const handelSelect = (event) => {
//     const idx = event.target.value;
//     dispatch(setSelectedClient(idx));
//   };

//   let clientSelector = "";

//   if (isAuth && clients.length === 1) {
//     clientSelector = (
//       <Paper
//         sx={{
//           paddingX: "5px",
//           marginTop: 1,
//           backgroundColor: "#0da6ab",
//           color: "white",
//         }}
//       >{`${clients[currClient]?.ID} ${clients[currClient]?.FullName}`}</Paper>
//     );
//   } else if (isAuth && clients.length > 1) {
//     clientSelector = (
//       <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }} size="small">
//         <Select
//           sx={{ color: "white" }}
//           id="select-client"
//           value={currClient}
//           onChange={handelSelect}
//         >
//           {clients.map((client, idx) => {
//             return (
//               <MenuItem
//                 key={"client" + idx}
//                 value={idx}
//               >{`${client.ID} ${client.FullName}`}</MenuItem>
//             );
//           })}
//         </Select>
//       </FormControl>
//     );
//   }

//   const menuItems = [
//     { label: "Home", path: "home" },
//     { label: "Open an account", path: "contact-form" },
//     { label: "contact us", path: "contact-us" },
//   ];

//   const theme = useTheme();

//   const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
//   const [open, setOpen] = React.useState(true);
//   const isMobile = () => /Mobi|Android/i.test(navigator.userAgent);
//   const shouldHideText = open && isMobile();
//   const handleDrawerOpen = () => {
//     setOpen(true);
//   };

//   const handleDrawerClose = () => {
//     setOpen(false);
//   };

//   const getPaddingRight = () => {
//     return location.pathname === "/account" ? { xs: "56px", md: "0" } : "0";
//   };

//   return (
//     <Box sx={{ display: "flex", overflow: "hidden", width: "100%" }}>
//       <CssBaseline />
//       <AppBar
//         position="fixed"
//         open={open}
//         sx={{ backgroundColor: "#263773", color: "#0da6ab" }}
//       >
//         <Toolbar>
//           {isSmallScreen && (
//             <IconButton
//               color="inherit"
//               aria-label="open drawer"
//               onClick={handleDrawerOpen}
//               edge="start"
//               sx={{
//                 marginRight: 5,
//                 ...(open && { display: "none" }),
//               }}
//             >
//               <MenuIcon />
//             </IconButton>
//           )}
//           <Typography
//             variant="h6"
//             component="div"
//             sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}
//           >
//             <img
//               src={logo}
//               alt="logo"
//               style={{
//                 height: "40px",
//                 backgroundColor: "white",
//                 marginRight: 5,
//                 borderRadius: "5px",
//                 cursor: "pointer",
//               }}
//               onClick={() => navigate("/")}
//             />
//             {/* <p>ACT Giving made easy</p> */}
//             {!shouldHideText && <p>ACT Giving made easy</p>}
//           </Typography>
//           <Box
//             sx={{ display: { xs: "none", lg: "flex" }, alignItems: "center" }}
//           >
//             {menuItems.map((menuItem, index) => (
//               <div key={index} style={{ marginLeft: 5 }}>
//                 <Button
//                   variant="text"
//                   sx={{ color: "#0da6ab" }}
//                   aria-controls={"menu-list-" + index}
//                   aria-haspopup="true"
//                   onClick={(event) => {
//                     handleMenu(event, index);
//                     navigate("/" + menuItem.path);
//                   }}
//                 >
//                   {menuItem.label}
//                 </Button>
//               </div>
//             ))}

//             {isAuth ? (
//               <>
//                 <Button
//                   variant="text"
//                   sx={{ color: "#0da6ab" }}
//                   aria-controls={"menu-list-"}
//                   aria-haspopup="true"
//                   onClick={() => navigate("/account")}
//                 >
//                   My account
//                 </Button>
//               </>
//             ) : null}
//           </Box>
//         </Toolbar>
//       </AppBar>

//       <Drawer variant="permanent" open={open}>
//         <div
//           style={{
//             backgroundColor: "#263773",
//             color: "white",
//             display: "flex",
//             flexDirection: "column",
//             minHeight: "100vh",
//           }}
//         >
//           <DrawerHeader>
//             {isSmallScreen && (
//               <IconButton onClick={handleDrawerClose}>
//                 {theme.direction === "rtl" ? (
//                   <ChevronRightIcon />
//                 ) : (
//                   <ChevronLeftIcon />
//                 )}
//               </IconButton>
//             )}
//           </DrawerHeader>
//           <Divider />
//           <ListItem
//             sx={{
//               minHeight: "100px",
//               display: "flex",
//               flexDirection: "column",
//               padding: "15px",
//             }}
//             key={Math.random()}
//           >
//             <Stack direction="row" spacing={2}>
//               <Avatar sx={{ bgcolor: "#0da6ab" }}>
//                 {clients[currClient]?.FullName?.[0]}{" "}
//               </Avatar>
//             </Stack>

//             {open ? clientSelector : null}
//           </ListItem>
//           <List>
//             {[
//               { label: "Dashboard", path: "/account", icon: <Dashboards /> },
//               {
//                 label: "Request a pay out",
//                 path: "/account/request-topup",
//                 icon: <CurrencyPoundIcon />,
//               },
//               ...(clients[currClient]?.Type === "Charity" &&
//               (!clients[currClient]?.CardStatus ||
//                 clients[currClient]?.CardStatus === "N/A")
//                 ? [
//                     {
//                       label: "Request a P.P. Card",
//                       path: "/account/request-card",
//                       icon: <AddCardIcon />,
//                     },
//                   ]
//                 : []),
//               {
//                 label: "Donate to a charity",
//                 path: "/account/internal-charity",
//                 icon: <VolunteerActivismIcon />,
//               },
//               {
//                 label: "Internal Transfer fund",
//                 path: "/account/internal-individual",
//                 icon: <ForwardIcon />,
//               },
//               {
//                 label: "My documents",
//                 path: "/account/my-documents",
//                 icon: <PendingIcon />,
//               },
//               // { label: "Grants", path: "/grants", disabled: true, icon: <PendingIcon /> },
//               {
//                 label: "Recurring",
//                 path: "/recurring-donations",
//                 disabled: true,
//                 icon: <PendingIcon />,
//               },
//               ...(coilelID
//                 ? [
//                     {
//                       label: "My recipients",
//                       path: "/account/my-recipients",
//                       icon: <PendingIcon />,
//                     },
//                   ]
//                 : []),
//               ...(coilelID
//                 ? [
//                     {
//                       label: "Distribution",
//                       path: "/account/distribution",
//                       icon: <PendingIcon />,
//                     },
//                   ]
//                 : []),
//               {
//                 label: "Send a request",
//                 path: "/send-request",
//                 disabled: true,
//                 icon: <PendingIcon />,
//               },
//               {
//                 label: "My Account detalis",
//                 path: "/account/account-details",
//                 icon: <AccountCircleIcon />,
//               },
//             ].map((text, index) => (
//               <Link
//                 key={Math.random()}
//                 to={text.path}
//                 style={{ textDecoration: "none" }}
//               >
//                 <ListItem disablePadding sx={{ width: "100%" }}>
//                   <ListItemButton
//                     sx={{
//                       backgroundColor:
//                         location.pathname === text.path ? "white" : "inherit",
//                       "&:hover": {
//                         backgroundColor: "lightblue",
//                       },
//                       display: "flex",
//                       color: "#0da6ab",
//                       fontWeight: "600",
//                     }}
//                     disabled={text.disabled}
//                   >
//                     <ListItemIcon sx={{ color: "#0da6ab" }}>
//                       {text.icon}
//                     </ListItemIcon>
//                     <ListItemText primary={text.label} />
//                   </ListItemButton>
//                 </ListItem>
//               </Link>
//             ))}
//             <ListItem sx={{ backgroundColor: "#263773", flexGrow: 1 }} />
//           </List>
//           <ListItem
//             disablePadding
//             sx={{ width: "100%" }}
//             onClick={handleLogout}
//           >
//             <ListItemButton
//               sx={{
//                 backgroundColor: "white",
//                 "&:hover": {
//                   backgroundColor: "lightblue",
//                 },
//                 display: "flex",
//                 color: "#0da6ab",
//                 fontWeight: "600",
//               }}
//             >
//               <ListItemIcon sx={{ color: "#0da6ab" }}>
//                 <LogoutIcon />
//               </ListItemIcon>
//               <ListItemText primary="Logout" />
//             </ListItemButton>
//           </ListItem>
//         </div>
//       </Drawer>

//       <Box
//         component="main"
//         sx={{
//           marginLeft: { xs: "56px", md: "65px", lg: "240px" },
//           marginTop: { xs: "56px", md: "72px" },
//           width: "100%",
//           paddingRight: getPaddingRight(),
//         }}
//       >
//         <Outlet />
//       </Box>
//     </Box>
//   );
// }
