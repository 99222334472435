import React from "react";

const PrimaryButton = ({
  type,
  label,
  onClick,
  backgroundColor,
  textColor = "text-primary",
  disabled = false,
  extraClass,
}) => {
  return (
    <button
      type={type}
      className={`${extraClass} w-[186px] flex justify-center items-center h-12 text-xl font-bold rounded-3xl ${backgroundColor} ${textColor} hover:scale-105 active:scale-95 transition-transform duration-200 ease-in-out`}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default PrimaryButton;
