import React, { useCallback, useMemo, useState } from "react";
import UserInfo from "../components/features/AccountDetails/UserInfo";
import { useSelector } from "react-redux";
import {
  useFetchClientsBalanceQuery,
  useResetPasswordMutation,
  useUpdateClientMutation,
} from "../store";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { resetPasswordSchema } from "../Validations/resetPasswordValidations";
import Loader from "../components/shared/Loader";

const AccountDetails = () => {
  const [showPassword, setShowPassword] = useState(false);
  const selectedClient = useSelector((state) => state.user.selectedClient);
  const client = useSelector((state) => state.user.clients[selectedClient]);
  const { ID, FullName, MobileUK, DefaultPayment } = client || {};
  const token = useSelector((state) => state.user.token);
  const balance = useSelector((state) => state.user.balance);

  const [updateClient, { isLoading }] = useUpdateClientMutation();
  const { data: currentBalance, isLoading: balanceLoading } =
    useFetchClientsBalanceQuery({ clientID: ID, token });
  console.log(currentBalance);
  const [resetPassword, { isLoading: resetPasswordLoading }] =
    useResetPasswordMutation();

  const handlePaymentMethodChange = useCallback(
    async (e) => {
      e.preventDefault();
      const res = await updateClient({
        clientID: ID,
        field: "DefaultPayment",
        value: e.target.value,
        token,
      });
      if (res?.data) {
        toast.success(res.data.message);
      } else {
        toast.error(res.error.data.message);
      }
    },
    [ID, token, updateClient]
  );

  const handleClickShowPassword = useCallback(
    () => setShowPassword((show) => !show),
    []
  );
  const handleMouseDownPassword = useCallback(
    (event) => event.preventDefault(),
    []
  );

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: resetPasswordSchema,

    onSubmit: async (values) => {
      try {
        await resetPassword({ token, newPassword: values.newPassword });
        toast.success("Password has been reset successfully.");
        resetForm();
      } catch (error) {
        toast.error("Error resetting password.");
      }
    },
  });

  const renderTopUpOptions = useMemo(() => {
    if (
      client?.CardStatus === "Active" ||
      client?.CardStatus === "Card Ordered"
    ) {
      return (
        <>
          <option value="Bank">Bank Pay out</option>
          <option value="P.P. Card">Prepaid Card Top-Up</option>
        </>
      );
    }
    return <option value="Bank">Bank Pay out</option>;
  }, [client]);

  if (isLoading || resetPasswordLoading || balanceLoading) {
    return <Loader />;
  }

  return (
    <section className=" space-y-7">
      <h2 className="text-5xl font-bold text-white">My Account Details</h2>
      <div className="rounded-lg shadow-lg w-full flex gap-5">
        {/* Left Section */}
        <div className="w-full bg-white rounded  lg:flex-1 flex px-6 py-2 gap-4">
          <div className="min-w-[100px]">
            <img
              src="/icons/details/Account.png"
              alt="profile"
              className="w-[100px] h-[100px]"
            />
          </div>

          <div className="w-full flex flex-col justify-around">
            <div className="flex justify-between font-bold border-b pb-1 border-[#D9D9D9] text-[#252525]">
              <p className="">Name :</p>
              <p className="">{FullName}</p>
            </div>
            <div className="flex justify-between font-bold border-b pb-1 border-[#D9D9D9] text-[#252525]">
              <p className="">Mobile Number :</p>
              <p className="">+{MobileUK}</p>
            </div>
            <div className="flex justify-between font-bold border-b pb-1 border-[#D9D9D9] text-[#252525]">
              <p className="">A.C.T Account ID :</p>
              <p className="">{ID}</p>
            </div>
            <div className="flex justify-between font-bold border-b pb-1 border-[#D9D9D9] text-[#252525]">
              <p className="">Default Payment method :</p>
              <p className="">{DefaultPayment}</p>
            </div>
            <div className="flex justify-between font-bold border-b pb-1 border-[#D9D9D9] text-[#252525]">
              <p className="">Your Account Balance :</p>
              <div className="flex items-center gap-1">
                <img
                  src="/icons/sidebar/Pound.png"
                  alt="pound"
                  className="w-4 h-4"
                />
                <p>{balance}</p>
              </div>
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="w-full bg-white rounded lg:flex-1 p-5 flex flex-col gap-4">
          <h2 className="text-lg font-bold text-primary">Card Overview</h2>
          <div className="space-y-5">
            <div className="border rounded-lg h-16 pl-2 pr-5 flex justify-between items-center">
              <div className="flex items-center gap-4">
                <img
                  src="/icons/details/master.png"
                  alt="Mastercard"
                  className="w-6 h-4"
                />
                <div>
                  <p className="text-[#252525]">Mastercard</p>
                  <p className="text-sm text-[#D9D9D9]">xxxx xxxx xxxx 2650</p>
                </div>
              </div>
              <span className="text-[#22A5EA] bg-[#E0F2FE] px-[6px] py-[3px] rounded-[2px] text-sm">
                Primary
              </span>
            </div>

            <div className="border rounded-lg h-16 pl-2 pr-5 flex justify-between items-center">
              <div className="flex items-center gap-4">
                <img
                  src="/icons/details/visa.png"
                  alt="Visa"
                  className="w-6 h-4"
                />
                <div>
                  <p className="text-[#252525]">Visa</p>
                  <p className="text-sm text-[#D9D9D9]">xxxx xxxx xxxx 2650</p>
                </div>
              </div>
            </div>

            <div className="border rounded-lg h-16 pl-2 pr-5 flex justify-between items-center">
              <div className="flex items-center gap-4">
                <img
                  src="/icons/details/discover.png"
                  alt="Discover"
                  className="w-6 h-4"
                />
                <div>
                  <p className="text-[#252525]">Discover</p>
                  <p className="text-sm text-[#D9D9D9]">xxxx xxxx xxxx 2650</p>
                </div>
              </div>
            </div>

            <div className="flex justify-center">
              <button
                href="#"
                className="text-[#3B92F8] font-bold flex items-center gap-1 hover:underline text-sm"
              >
                Add new card
                <img src="/icons/details/DownArrow.png" alt="DownArrow" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-auto rounded bg-white p-5">
        <h2 className="text-lg text-primary font-bold mb-8">Payment History</h2>
        <table className="w-full text-left overflow-hidden">
          <thead className="border-b text-[#000000] border-[#DEDEDE]">
            <tr>
              <th className="py-2 font-bold pl-5">Date</th>
              <th className="py-2 font-bold">Amount</th>
              <th className="py-2 font-bold">Status</th>
              <th className="py-2 font-bold">Description</th>
              <th className="py-2 font-bold w-20 text-center">PDF</th>
            </tr>
          </thead>
          <tbody>
            {[...Array(10)].map((_, index) => (
              <tr
                key={index}
                className={`text-[#000000] text-xs ${
                  index % 2 === 0 ? "bg-gray-100" : "bg-white"
                } hover:bg-gray-200`}
              >
                <td className="py-4 px-5">
                  <p className="text-xs">2 Aug, 2024</p>
                </td>
                <td className="flex items-center">
                  <img
                    src="/icons/sidebar/Pound.png"
                    alt="pound"
                    className="w-[10px] h-[10px]"
                  />
                  <span className="truncate">321.00</span>
                </td>
                <td className="">Paid</td>
                <td className="">Prepaid bill</td>
                <td className="flex py-4 justify-center items-center">
                  <button className="text-blue-500 hover:text-blue-700">
                    <img src="/icons/details/Download.png" alt="download" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
    // <section>
    //   <UserInfo />
    // </section>
  );
};

export default AccountDetails;
