import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { Box, Button, IconButton, Paper, Tooltip } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AddCardIcon from "@mui/icons-material/AddCard";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import { useFetchCoilelClientsQuery, useUpdateClientMutation } from "../store";
import FilterableTable from "../components/features/Dashboard/FilterableTable";
import MainDialog from "../components/Dialog/MainDialog";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "../components/features/Dashboard/css/pdf.css";
import { Grid } from "react-loader-spinner";
import DocumentViewer from "../components/features/MyDocuments/DocumentViewer";
import { useUploadDocMutation } from "../store/apis/documentApi";
import Loader from "../components/shared/Loader";

export default function Recipients() {
  const [dialogState, setDialogState] = useState({
    isOpen: false,
    name: "",
    forID: "",
  });
  const [defaultPayment, setDefaultPayment] = useState("");
  const [tableData, setTableData] = useState([]);
  const [file, setFile] = useState(null);
  const [rowData, setRowData] = useState({});
  const selectedClient = useSelector((state) => state.user.selectedClient);
  const client = useSelector((state) => state.user.clients[selectedClient]);
  const token = useSelector((state) => state.user.token);
  const { ID, MobileAbroad, DefaultPayment } = client || {};
  const payload = useMemo(
    () => ({ clientID: ID, coilelID: MobileAbroad, token }),
    [ID, MobileAbroad, token]
  );
  const {
    data = [],
    error,
    isLoading,
    isFetching,
    refetch,
  } = useFetchCoilelClientsQuery(payload);
  const [updateClient, { isLoading: isUpdatingUser }] =
    useUpdateClientMutation();
  const navigate = useNavigate();
  const [uploadDoc, { isLoading: fileLoading }] = useUploadDocMutation();

  useEffect(() => {
    if (token) refetch();
  }, [token, refetch]);

  useEffect(() => {
    if (data?.data) setTableData(data.data);
  }, [data, rowData?.original?.ID, rowData?.id]);

  useEffect(() => {
    setDefaultPayment(DefaultPayment);
  }, [DefaultPayment]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "ID",
        header: "ID",
        size: 80,
        filterFn: "equals",
      },
      {
        accessorKey: "FullName",
        header: "Name",
        size: 80,
        filterFn: "contains",
      },
      {
        accessorKey: "DefaultPayment",
        header: "Default Payment method",
        filterFn: "equals",
        filterSelectOptions: ["Bank", "P.P. Card"],
        filterVariant: "select",
      },
      {
        id: "CardStatus",
        accessorFn: (r) =>
          r.CardStatus === "Need To order" ? "Processing" : r.CardStatus,
        header: "P.P. Card Status",
        filterFn: "equals",
        filterSelectOptions: ["Processing", "Ordered", "Active", "N/A"],
        filterVariant: "select",
      },
    ],
    []
  );

  const handleDefaultPayment = useCallback((row) => {
    if (
      row?.original.CardStatus === "Active" ||
      row?.original.CardStatus === "Orderd"
    ) {
      setDialogState({
        isOpen: true,
        name: "changePaymentMethod",
        forID: row?.original?.ID,
      });
    } else {
      toast.error("You don't have any active Card.");
    }
  }, []);

  const handlePaymentMethodChange = useCallback((e) => {
    setDefaultPayment(e.target.value);
  }, []);

  const submitPaymentMethod = useCallback(async () => {
    setDialogState({ ...dialogState, isOpen: false });
    const res = await updateClient({
      clientID: ID,
      forID: dialogState.forID,
      field: "DefaultPayment",
      value: defaultPayment,
      token,
    });
    if (res?.data) {
      refetch();
      toast.success(res.data.message);
    } else {
      toast.error(res.error.data.message);
    }
  }, [defaultPayment, dialogState, ID, token, updateClient, refetch]);

  const handleRequestCard = useCallback((row) => {
    if (row?.original?.CardStatus === "N/A") {
      setDialogState({
        isOpen: true,
        name: "requestCard",
        forID: row?.original?.ID,
      });
    } else {
      toast.error("You have already a card on file!");
    }
  }, []);

  const submitRequestCard = useCallback(async () => {
    try {
      const res = await updateClient({
        clientID: ID,
        forID: dialogState.forID,
        field: "CardStatus",
        value: "Ordered",
        token,
      }).unwrap();
      toast.success(res.message);
      navigate("/account");
    } catch (error) {
      toast.error(
        error.data?.message ||
          "An error occurred while processing your request."
      );
    }
  }, [ID, dialogState, token, updateClient, navigate]);

  const handleRemove = useCallback((row) => {
    setDialogState({
      isOpen: true,
      name: "deleteCoilel",
      forID: row?.original?.ID,
    });
  }, []);

  const submitDeleteCoilel = useCallback(async () => {
    setDialogState({ ...dialogState, isOpen: false });
    try {
      const res = await updateClient({
        clientID: ID,
        forID: dialogState.forID,
        field: "Group",
        value: null,
        token,
      }).unwrap();
      if (res) {
        refetch();
        toast.success(res?.message);
      } else {
        toast.error(res.error.data.message);
      }
    } catch (error) {
      toast.error("An error occurred while processing your request.");
    }
  }, [dialogState, ID, token, updateClient, refetch]);

  const handleExportRows = useCallback((rows) => {
    const rowData = rows.map((row) => {
      const { ID, FullName, DefaultPayment, CardStatus } = row.original;
      return {
        ID,
        FullName,
        DefaultPayment,
        CardStatus: CardStatus === "Need To order" ? "Processing" : CardStatus,
      };
    });
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  }, []);

  const csvConfig = useMemo(
    () =>
      mkConfig({
        fieldSeparator: ",",
        useKeysAsHeaders: true,
        filename: "ACTClients",
      }),
    []
  );

  const dialogSubmitHandler = useMemo(() => {
    switch (dialogState.name) {
      case "changePaymentMethod":
        return submitPaymentMethod;
      case "requestCard":
        return submitRequestCard;
      case "deleteCoilel":
        return submitDeleteCoilel;
      default:
        return null;
    }
  }, [
    dialogState.name,
    submitPaymentMethod,
    submitRequestCard,
    submitDeleteCoilel,
  ]);

  const dialogContent = useMemo(() => {
    switch (dialogState.name) {
      case "changePaymentMethod":
        return (
          <div className="user-detail">
            <div>
              <span className="user-label">Default Payment method:</span>
              <select
                className="user-value"
                value={defaultPayment}
                onChange={handlePaymentMethodChange}
              >
                <option value="" disabled>
                  Please Select
                </option>
                <option value="Bank">Bank Pay out</option>
                <option value="P.P. Card">Prepaid Card Top-Up</option>
              </select>
            </div>
          </div>
        );
      case "requestCard":
        return (
          <div style={{ width: "100%" }}>
            <h1>Are you sure to request card?</h1>
          </div>
        );
      case "deleteCoilel":
        return (
          <div style={{ width: "100%" }}>
            <h1>Are you sure to remove from your Coilel?</h1>
          </div>
        );
      default:
        return null;
    }
  }, [dialogState.name, defaultPayment, handlePaymentMethodChange]);
  console.log(rowData?.id);
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("permission", "Office only");
      // formData.append("customerID", rowData?.original?.ID);
      formData.append("customerID", client.ID);
      formData.append("customerAccountID", rowData?.id);

      try {
        await uploadDoc({ fileData: formData, token, clientID: ID });
        toast.success("File uploaded successfully.");
        setFile(null);
      } catch (err) {
        toast.error("There was an server side error!");
      }
    } else {
      toast.error("No file selected for upload!");
    }
  };

  const options = {
    muiToolbarAlertBannerProps: error
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Change Default payment method">
          <IconButton onClick={() => handleDefaultPayment(row)}>
            <PriceChangeIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Request a P.P. Card">
          <IconButton
            disabled={row.CardStatus === "Ordered"}
            onClick={() => handleRequestCard(row)}
          >
            <AddCardIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Remove from your Coilel">
          <IconButton color="error" onClick={() => handleRemove(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Upload Doc">
          <IconButton color="error" onClick={() => setRowData(row)}>
            <input
              accept=".csv,application/pdf, image/jpeg"
              style={{ display: "none" }}
              id="file-upload"
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="file-upload">
              <CloudUploadIcon className="upload-button" />
            </label>
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Box>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          Download csv
        </Button>
      </Box>
    ),
  };

  const state = useMemo(
    () => ({
      isLoading,
      isSaving: isUpdatingUser,
      showAlertBanner: error,
      showProgressBars: isFetching,
    }),
    [isLoading, isUpdatingUser, error, isFetching]
  );

  // Loader overlay rendering approach
  const renderLoaderOverlay = () => {
    if (fileLoading) {
      return (
        <div className="loader-overlay">
          <Loader />
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {renderLoaderOverlay()} {/* Loader overlay */}
      <div className="tableContainer extraPadding" style={{ width: "100%" }}>
        <FilterableTable
          data={tableData || data}
          columns={columns}
          options={options}
          state={state}
        />
        <MainDialog
          cardBody={dialogContent}
          isDialogOpen={dialogState.isOpen}
          setIsDialogOpen={(isOpen) =>
            setDialogState({ ...dialogState, isOpen })
          }
          submitHandler={dialogSubmitHandler}
        />
      </div>
    </>
  );
}
