import { useEffect, useState, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  useFetchClientsTransactionsQuery,
  useSendInvoiceMailMutation,
} from "../../../store";
import { Box, Button, ListItemIcon, MenuItem } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SendIcon from "@mui/icons-material/Send";
import FilterableTable from "./FilterableTable";
import { mkConfig, generateCsv, download } from "export-to-csv";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import pdfBanner from "../../../assets/pdfBanner.png";
import "./css/pdf.css";
import { toast } from "react-toastify";

export default function TransactionsTable() {
  const [tColumnInfo, setTColumnInfo] = useState("");
  const selectedClient = useSelector((state) => state.user.selectedClient);
  const client = useSelector((state) => state.user.clients[selectedClient]);
  const token = useSelector((state) => state.user.token);
  const payload = { clientID: client?.ID, token };

  const { data, error, isLoading, isFetching, refetch } =
    useFetchClientsTransactionsQuery(payload, {
      skip: !client?.ID,
    });

  const [sendInvoiceMail] = useSendInvoiceMailMutation();
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (client?.ID) {
      refetch();
    }
  }, [client, refetch]);

  useEffect(() => {
    if (data?.data) {
      setTableData(data.data);
    }
  }, [data]);

  const uniqueTypes = useMemo(() => {
    return [
      ...new Set(
        (tableData || []).map((row) => `${row.PaidIn_PaidOut}:${row.OnHold}`)
      ),
    ];
  }, [tableData]);

  const types = useMemo(
    () => ({
      1: "Paid In",
      "-1": "Paid Out",
      2: "Credit",
      "-2": "Debit",
      "-3": "Fees",
      4: "Int. Received",
      "-4": "Int. Send",
    }),
    []
  );

  const transactionTypes = useMemo(() => {
    return uniqueTypes.map((type) => {
      const [paidInPaidOut, onHold] = type.split(":");
      return onHold === "true"
        ? `Pending ${types[paidInPaidOut]}`
        : types[paidInPaidOut];
    });
  }, [uniqueTypes, types]);

  const moneyTypes = useMemo(() => {
    return [...new Set((tableData || []).map((row) => row.MoneyType))].filter(
      (value) => value !== null && value !== ""
    );
  }, [tableData]);

  const columns = useMemo(
    () => [
      {
        accessorFn: (originalRow) => new Date(originalRow.DDate),
        size: 50,
        header: "Date",
        filterVariant: "date-range",
        Cell: ({ cell }) => cell.getValue().toLocaleDateString("en-GB"),
      },
      {
        header: "Transaction type",
        id: "PaidIn_PaidOut",
        size: 50,
        accessorFn: (r) =>
          r.OnHold
            ? `Pending ${types[r.PaidIn_PaidOut]}`
            : types[r.PaidIn_PaidOut],
        filterFn: "equals",
        filterSelectOptions: transactionTypes,
        filterVariant: "select",
        enableColumnFilterModes: false,
        height: "50px",
      },
      {
        header: "Amount",
        accessorKey: "Amount",
        size: 50,
        Cell: ({ cell }) => "£ " + cell.getValue(),
        filterFn: "equals",
        columnFilterModeOptions: [
          "equals",
          "betweenInclusive",
          "lessThan",
          "greaterThan",
        ],
      },
      {
        header: "Money Type",
        id: "MoneyType",
        size: 50,
        accessorFn: (r) => r.MoneyType || "",
        filterFn: "equals",
        filterSelectOptions: moneyTypes,
        filterVariant: "select",
        enableColumnFilterModes: false,
      },
      {
        header: "Fee",
        accessorKey: "Fee",
        size: 50,
        Cell: ({ cell }) => "£ " + cell.getValue(),
        filterFn: "equals",
        columnFilterModeOptions: [
          "equals",
          "betweenInclusive",
          "lessThan",
          "greaterThan",
        ],
      },
      {
        header: "Total",
        accessorKey: "Total",
        size: 50,
        Cell: ({ cell }) => "£ " + cell.getValue(),
        filterFn: "equals",
        columnFilterModeOptions: [
          "equals",
          "betweenInclusive",
          "lessThan",
          "greaterThan",
        ],
      },
      {
        header: "Note",
        size: 50,
        accessorKey: "Note",
        filterFn: "contains",
        columnFilterModeOptions: ["equals", "contains"],
      },
    ],
    [moneyTypes, transactionTypes, types]
  );

  const downloadPDF = useCallback((row) => {
    setTColumnInfo(row.original);
    setTimeout(() => {
      const doc = new jsPDF("p", "pt", "a4");
      const input = document.getElementById("invoice");

      html2canvas(input, { backgroundColor: "#ffffff" }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

        doc.setFillColor(255, 255, 255);
        doc.rect(0, 0, pdfWidth, pdfHeight, "F");

        doc.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        doc.save("invoice.pdf");
      });
    }, 100);
  }, []);

  const generateAndSendPDF = useCallback(
    (row) => {
      setTColumnInfo(row.original);
      // Invoice type
      const invoiceType =
        tColumnInfo?.PaidIn_PaidOut < 0 ? "Remittance" : "Receipt";
      setTimeout(() => {
        const doc = new jsPDF("p", "pt", "a4");
        const input = document.getElementById("invoice");

        html2canvas(input, { backgroundColor: "#ffffff" }).then(
          async (canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdfWidth = doc.internal.pageSize.getWidth();
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

            doc.setFillColor(255, 255, 255);
            doc.rect(0, 0, pdfWidth, pdfHeight, "F");

            doc.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

            // Convert PDF to blob
            const pdfBlob = doc.output("blob");

            // Create FormData object and append the blob
            const formData = new FormData();
            formData.append("pdf", pdfBlob, "invoice.pdf");

            try {
              await sendInvoiceMail({
                token,
                clientID: client?.ID,
                IDTransactions: row.original?.IDTransactions,
                formData,
                invoiceType,
              });

              toast.success("Email has been sent successfully.");
            } catch (error) {
              toast.error("There was an server side error!");
            }
          }
        );
      }, 100);
    },
    [client?.ID, sendInvoiceMail, tColumnInfo, token]
  );

  const csvConfig = useMemo(
    () =>
      mkConfig({
        fieldSeparator: ",",
        useKeysAsHeaders: true,
        filename: "ACTTransactions",
      }),
    []
  );

  const handleExportRows = useCallback(
    (rows) => {
      const rowData = rows.map((row) => {
        const { Amount, PaidIn_PaidOut, MoneyType, Fee, Total, Note, OnHold } =
          row.original;
        const noneNull = JSON.parse(
          JSON.stringify({ Amount, MoneyType, Fee, Total, Note }).replace(
            /null/g,
            '""'
          )
        );
        return {
          Date: new Date(row.original.DDate).toLocaleDateString("en-GB"),
          "Transaction type": OnHold
            ? `Pending ${types[PaidIn_PaidOut]}`
            : types[PaidIn_PaidOut],
          ...noneNull,
        };
      });
      const csv = generateCsv(csvConfig)(rowData);
      download(csvConfig)(csv);
    },
    [csvConfig, types]
  );

  const options = useMemo(
    () => ({
      enableRowActions: true,
      enableColumnActions: false,
      muiTableBodyRowProps: ({ row }) => ({
        sx: {
          "& .MuiTableCell-root": {
            color: row.original.PaidIn_PaidOut < 0 ? "red" : "inherit",
            fontWeight: row.original.OnHold ? 100 : "bold",
            "& .MuiSvgIcon-root": {
              color: "#665a5a !important",
            },
          },
        },
      }),
      renderRowActionMenuItems: ({ closeMenu, row }) => [
        <MenuItem
          key={0}
          onClick={() => {
            closeMenu();
            downloadPDF(row);
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          Download Receipt
        </MenuItem>,
        <MenuItem
          key={1}
          onClick={() => {
            closeMenu();
            generateAndSendPDF(row);
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <SendIcon />
          </ListItemIcon>
          Receipt Email
        </MenuItem>,
      ],
      renderTopToolbarCustomActions: ({ table }) => (
        <Box>
          <Button
            disabled={table.getPrePaginationRowModel().rows.length === 0}
            onClick={() =>
              handleExportRows(table.getPrePaginationRowModel().rows)
            }
            startIcon={<FileDownloadIcon />}
            sx={{ fontSize: { xs: "12px", md: "16px" } }}
          >
            Download csv
          </Button>
        </Box>
      ),
    }),
    [downloadPDF, generateAndSendPDF, handleExportRows]
  );

  const state = useMemo(
    () => ({
      isLoading: isLoading,
      showAlertBanner: error,
      showProgressBars: isFetching,
    }),
    [isLoading, error, isFetching]
  );

  //distructer row data
  const { Amount, DDate, Note, PaidIn_PaidOut, Total } = tColumnInfo || {};

  //formate transaction date
  const transactionDateStr = new Date(DDate);
  const formattedTransactionDate = `${(
    "0" + transactionDateStr.getDate()
  ).slice(-2)}/${("0" + (transactionDateStr.getMonth() + 1)).slice(
    -2
  )}/${transactionDateStr.getFullYear()}`;

  //transaction type
  let transactionType;
  switch (PaidIn_PaidOut) {
    case 1:
      transactionType = "PaidIn-PaidOut";
      break;
    case -1:
      transactionType = "Paid In";
      break;
    case 2:
      transactionType = "Paid Out";
      break;
    case -2:
      transactionType = "Credit";
      break;
    case -3:
      transactionType = "Debit";
      break;
    case 4:
      transactionType = "Fees";
      break;
    case -4:
    case 5:
      transactionType = "Int. Received";
      break;
    default:
      transactionType = "Unknown";
  }

  //invoice type
  const invoiceType = PaidIn_PaidOut < 0 ? "Remittance" : "Receipt";

  //for date formate
  const formatDate = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <>
      <FilterableTable
        data={tableData}
        columns={columns}
        options={options}
        state={state}
      />
      <div
        id="invoice"
        style={{
          fontFamily: "Arial, sans-serif",
          margin: "0",
          padding: "0",
          boxSizing: "border-box",
          width: "100%",
          position: "absolute",
          left: "-1500px",
          top: "-1500px",
        }}
      >
        <div
          style={{ width: "100%", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}
          className="forceWhite"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
            }}
            className="forceWhite"
          >
            <img style={{ width: "100%" }} src={pdfBanner} alt="Logo" />
            <p
              style={{
                paddingRight: "130px",
                fontSize: "20px",
                color: "#61605f",
                fontWeight: "600",
              }}
              className="forceWhite"
            >
              {formatDate()}
            </p>
          </div>
          <div
            style={{
              textAlign: "center",
              fontSize: "34px",
              padding: "20px 0",
              fontWeight: "600",
            }}
            className="forceWhite"
          >
            {invoiceType}
          </div>
          {invoiceType === "Remittance" ? (
            <div
              style={{
                padding: "0 70px",
                textAlign: "center",
                fontSize: "18px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              className="forceWhite"
            >
              <p>
                Payment will be made by BACS : Sort-code: 13254 Bank Account
                No:4654125 , or your preferred method of payment. If these
                details are incorrect, please contact us immediately.
              </p>
            </div>
          ) : null}
          <div
            style={{
              padding: "70px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
            className="forceWhite"
          >
            <div
              style={{
                width: "90%",
                fontSize: "16px",
                padding: "10px",
                height: "100px",
                border: "1px solid #000",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "start",
              }}
              className="forceWhite"
            >
              <strong style={{ margin: "0" }}>Charity details:</strong>
              <p style={{ margin: "0" }}>Name: {client?.FullName}</p>
              <p style={{ margin: "0" }}>account number: 21778</p>
              <p style={{ margin: "0" }}>Mobile number: {client?.MobileUK}</p>
            </div>
          </div>
          <div style={{ paddingLeft: "80px", paddingRight: "80px" }}>
            <div
              style={{
                marginBottom: "20px",
                display: "flex",
                paddingBottom: "10px",
                justifyContent: "center",
                border: "1px solid #000",
              }}
              className="forceWhite"
            >
              <table
                style={{ width: "99%", borderCollapse: "collapse" }}
                className="forceWhite"
              >
                {" "}
                <thead>
                  <tr>
                    <th
                      style={{
                        border: "1px solid #000",
                        padding: "8px",
                        backgroundColor: "#f0f0f0",
                      }}
                    >
                      Date
                    </th>
                    <th
                      style={{
                        border: "1px solid #000",
                        padding: "8px",
                        backgroundColor: "#f0f0f0",
                      }}
                    >
                      Transaction Type
                    </th>
                    <th
                      style={{
                        border: "1px solid #000",
                        padding: "8px",
                        backgroundColor: "#f0f0f0",
                      }}
                    >
                      Type
                    </th>
                    <th
                      style={{
                        border: "1px solid #000",
                        padding: "8px",
                        backgroundColor: "#f0f0f0",
                      }}
                    >
                      Grants For
                    </th>
                    <th
                      style={{
                        border: "1px solid #000",
                        padding: "8px",
                        backgroundColor: "#f0f0f0",
                      }}
                    >
                      Note
                    </th>
                    <th
                      style={{
                        border: "1px solid #000",
                        padding: "8px",
                        backgroundColor: "#f0f0f0",
                      }}
                    >
                      Amount
                    </th>
                    <th
                      style={{
                        border: "1px solid #000",
                        padding: "8px",
                        backgroundColor: "#f0f0f0",
                      }}
                    >
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ border: "1px solid #000", padding: "8px" }}>
                      {formattedTransactionDate}
                    </td>
                    <td style={{ border: "1px solid #000", padding: "8px" }}>
                      {transactionType}
                    </td>
                    <td style={{ border: "1px solid #000", padding: "8px" }}>
                      Charity Extra
                    </td>
                    <td
                      style={{ border: "1px solid #000", padding: "8px" }}
                    ></td>
                    <td style={{ border: "1px solid #000", padding: "8px" }}>
                      {Note}
                    </td>
                    <td style={{ border: "1px solid #000", padding: "8px" }}>
                      £{Amount}
                    </td>
                    <td style={{ border: "1px solid #000", padding: "8px" }}>
                      £${Total}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
