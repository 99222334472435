import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useRequestTopupMutation } from "../../../store";
import { toast } from "react-toastify";
import Loader from "../../shared/Loader";
import "./css/requestTopup.css";

const RequestTopUpComponent = () => {
  const selectedClient = useSelector((state) => state.user.selectedClient);
  const client = useSelector((state) => state.user.clients[selectedClient]);
  const token = useSelector((state) => state.user.token);
  const balance = useSelector((state) => state.user.balance);

  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  const [moneyType, setMoneyType] = useState(client?.DefaultPayment || "Bank");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestTopup] = useRequestTopupMutation();

  const handleAmountChange = (e) => setAmount(e.target.value);
  const handleNoteChange = (e) => setNote(e.target.value);
  const handleMoneyTypeChange = (e) => setMoneyType(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (parseFloat(amount) > balance) {
      toast.error("Insufficient balance.");
      return;
    }
    setIsSubmitting(true);
    try {
      const res = await requestTopup({
        clientID: client?.ID,
        moneyType,
        amount,
        note,
        token,
      });
      if (res?.data) {
        toast.success(res.data.message);
        setAmount("");
        setNote("");
      } else {
        toast.error(res.error.data.message);
      }
    } catch (error) {
      toast.error("Error requesting top-up.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const topUpOptions = useMemo(() => {
    if (
      client?.CardStatus === "Active" ||
      client?.CardStatus === "Card Ordered"
    ) {
      return (
        <>
          <option value="Bank">Bank Pay out</option>
          <option value="P.P. Card">Prepaid Card Top-Up</option>
        </>
      );
    }
    return <option value="Bank">Bank Pay out</option>;
  }, [client?.CardStatus]);

  if (isSubmitting) {
    return <Loader />;
  }

  return (
    <section>
      <div>
        <h2 className="text-5xl font-bold text-white mb-5">Request a Top-Up</h2>
        <p className="text-white text-lg font-bold mb-7">
          Current Balance: <span className="font-semibold">£ {balance}</span>
        </p>
        <form
          className="bg-white py-7 px-5 text-[#252525]"
          onSubmit={handleSubmit}
        >
          <div className="w-8/12">
            <div className="grid grid-cols-2 gap-6 mb-9">
              {/* Amount */}
              <div>
                <label htmlFor="amount" className="block font-medium mb-3">
                  Amount
                </label>
                <select
                  id="amount"
                  className="w-full rounded border border-borderColor3 p-3 focus:ring-blue-500 focus:border-blue-500"
                  value={amount}
                  onChange={handleAmountChange}
                >
                  <option value={19}>£19</option>
                  <option value={50}>£50</option>
                  <option value={100}>£100</option>
                </select>
              </div>

              {/* Pay out method */}
              <div>
                <label
                  htmlFor="payOutMethod"
                  className="block font-medium mb-3"
                >
                  Pay out method
                </label>
                <select
                  id="moneyType"
                  className="w-full rounded border border-borderColor3 p-3 focus:ring-blue-500 focus:border-blue-500"
                  value={moneyType}
                  onChange={handleMoneyTypeChange}
                >
                  {topUpOptions}
                </select>
              </div>
            </div>

            {/* Notes */}
            <div className="mb-6">
              <label htmlFor="notes" className="block font-medium mb-3">
                Notes
              </label>
              <textarea
                id="notes"
                value={note}
                onChange={handleNoteChange}
                rows="4"
                className="w-full border border-borderColor3 rounded p-3 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter additional notes here..."
              />
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-[200px] h-[50px] text-lg bg-primary text-white font-bold py-3 rounded hover:bg-blue-800 transition"
            >
              SUBMIT
            </button>
          </div>
        </form>
      </div>
    </section>
    // <div className="topup-wrapper">
    //   <div className="topup-container">
    //     <h2 className="topup-header">Request a Top-Up</h2>
    //     <form onSubmit={handleSubmit} className="form">
    //       <div className="topup-formGroup">
    //         <label className="topup-label">Amount:</label>
    //         <input
    //           type="number"
    //           value={amount}
    //           onChange={handleAmountChange}
    //           className="topup-input"
    //           required
    //         />
    //       </div>
    //       <div className="topup-formGroup">
    //         <label className="topup-label">Pay out method:</label>
    //         <select
    //           value={moneyType}
    //           onChange={handleMoneyTypeChange}
    //           className="topup-select"
    //         >
    //           {topUpOptions}
    //         </select>
    //       </div>
    //       <div className="topup-formGroup">
    //         <label className="topup-label">Note:</label>
    //         <input
    //           type="text"
    //           value={note}
    //           onChange={handleNoteChange}
    //           className="topup-input"
    //         />
    //       </div>
    //       <Button
    //         variant="contained"
    //         color="primary"
    //         type="submit"
    //         fullWidth
    //         sx={{ mt: 2 }}
    //       >
    //         Submit
    //       </Button>
    //     </form>
    //     <div
    //       style={{ marginTop: "20px", textAlign: "center", color: "#555" }}
    //     >
    //       <p>Current Balance: £ {balance}</p>
    //     </div>
    //   </div>
    // </div>
  );
};

export default RequestTopUpComponent;
