import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { checkTokenExpiration, successLogout } from "./store";
import Users from "./components/Users";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import EmailVerificationPage from "./pages/EmailVerificationPage";
import DashboardPage from "./pages/DashboardPage";
import RequestForAccount from "./pages/RequestForAccount";
import Grants from "./pages/Grants";
import Recurring from "./pages/Recurring";
import Recipients from "./pages/Recipients";
import SendRequest from "./pages/SendRequest";
import AccountLayout from "./components/Layouts/AccountLayout";
import NotFoundPage from "./pages/NotFoundPage";
import AccountDetails from "./pages/AccountDetails";
import RequestCard from "./pages/RequestCard";
import RequestTopup from "./pages/RequestTopup";
import DonateToCharity from "./pages/DonateToCharity";
import TransfarFunds from "./pages/TransfarFunds";
import Contact from "./components/features/Home/Contact";
import ResetPassword from "./pages/ResetPassword";
import PasswordResetConfirmation from "./pages/PasswordMailConfirm";
import { ToastContainer } from "react-toastify";
import Root from "./components/Layouts/Root";
import MyDocuments from "./pages/MyDocuments";
import Distribution from "./pages/Distribution";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Signup from "./pages/Signup";
import Test from "./pages/Test";

const App = () => {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const tokenExpiration = useSelector((state) => state.user.tokenExpiration);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isResetPassword = location.pathname.includes("reset-password");

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(checkTokenExpiration());
    }, 1000 * 60);

    return () => clearInterval(interval);
  }, [dispatch]);

  useEffect(() => {
    if (
      Date.now() >= tokenExpiration &&
      location.pathname !== "/contact-us" &&
      location.pathname !== "/about-us" &&
      location.pathname !== "/home" &&
      location.pathname !== "/contact-form" &&
      location.pathname !== "/test" &&
      location.pathname !== "/" &&
      location.pathname !== "/signup" &&
      location.pathname !== "/my-documents" &&
      !isResetPassword
    ) {
      dispatch(successLogout());
      navigate("/login");
    }
  }, [tokenExpiration, navigate, dispatch, location.pathname, isResetPassword]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Root />}>
          <Route index element={<HomePage />} />
          <Route path="home" element={<HomePage />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="signup" element={<Signup />} />
          <Route path="reset-password/:token" element={<ResetPassword />} />
          <Route
            path="verify-email/:token"
            element={<EmailVerificationPage />}
          />
          <Route path="confirm-mail" element={<PasswordResetConfirmation />} />
          <Route path="users" element={<Users />} />
          <Route path="contact-form" element={<RequestForAccount />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="test" element={<Test />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>

        {isAuthenticated && (
          <Route path="/account/*" element={<AccountLayout />}>
            <Route index element={<DashboardPage />} />
            <Route path="grants" element={<Grants />} />
            <Route path="request-card" element={<RequestCard />} />
            <Route path="request-topup" element={<RequestTopup />} />
            <Route path="internal-charity" element={<DonateToCharity />} />
            <Route path="internal-individual" element={<TransfarFunds />} />
            <Route path="recurring-donations" element={<Recurring />} />
            <Route path="my-recipients" element={<Recipients />} />
            <Route path="send-request" element={<SendRequest />} />
            <Route path="account-details" element={<AccountDetails />} />
            <Route path="my-documents" element={<MyDocuments />} />
            <Route path="distribution" element={<Distribution />} />
          </Route>
        )}
      </Routes>
      <ToastContainer autoClose={20000} position={"top-right"} />
    </>
  );
};

export default App;
