import { useRef } from "react";
import Hero from "../components/features/Home/Hero";
import FundraisingPower from "../components/features/Home/FundraisingPower";
import Services from "../components/features/Home/Services";
import MissionVisionValues from "../components/features/Home/MissionVisionValues";

function HomePage() {
  const contactRef = useRef(null);
  const handleContactScroll = () => {
    contactRef?.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div>
      <Hero handleContactScroll={handleContactScroll} />
      <FundraisingPower />
      <Services />
      <MissionVisionValues />
    </div>
  );
}

export default HomePage;
