import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  GoogleLogin,
  GoogleOAuthProvider,
  useGoogleLogin,
} from "@react-oauth/google";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import {
  googleClientID,
  successLogin,
  useForgotPasswordMutation,
  useLoginByGoogleMutation,
  useLoginByPasswordMutation,
} from "../store";

import { loginSchema } from "../Validations/LoginValidations";
import { useFormik } from "formik";
import Loader from "../components/shared/Loader";
import GoogleLoginBtn from "../components/button/GoogleLoginBtn";

function LoginPage() {
  const [showPassword, setShowPassword] = useState(false);

  const [LoginByGoogle, LoginByGoogleResult] = useLoginByGoogleMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //GOOGLE
  const handleSuccessGoogleLogin = async (response) => {
    const { credential } = response;
    console.log(credential);
    const res = await LoginByGoogle(credential);
    if (res.data) {
      const payload = {
        token: res.data?.data?.token?.token,
        expiresIn: res.data?.data?.token?.expiresIn,
        user: res.data?.data?.user,
      };
      dispatch(successLogin(payload));
      toast.success("You have logged in successfully");
      navigate("/account");
    } else {
      toast.error(res.error.data.message);
    }
  };

  const handleFailureGoogleLogin = (error) => {
    toast.error("Google sign-in failed:", error);
  };

  const [LoginByPassword, LoginByPasswordResult] = useLoginByPasswordMutation();
  const [ForgotPassword, { isLoading }] = useForgotPasswordMutation();

  // Handlers
  const onSubmit = useCallback(
    async (values, actions) => {
      const res = await LoginByPassword(values);
      if (res.error) {
        toast.error("Failed to login: " + res.error.data.message);
      } else {
        dispatch(
          successLogin({
            user: res?.data?.data.user,
            token: res?.data?.data.token.token,
            expiresIn: res?.data?.data.token.expiresIn,
          })
        );
        toast.success("Welcome back.");
        navigate("/account");
      }
      actions.resetForm();
    },
    [LoginByPassword, dispatch, navigate]
  );

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit,
  });
  useEffect(() => {
    console.log(errors);
  }, [errors]);
  const handleForgotPassword = useCallback(async () => {
    if (!values.email) return;
    const res = await ForgotPassword({ email: values.email });
    if (res.error) {
      toast.error("Failed to send recovery email: " + res.error.status);
    } else {
      toast.success("Recovery email sent");
      navigate("/confirm-mail");
    }
  }, [ForgotPassword, values.email, navigate]);

  const handleClickShowPassword = useCallback(
    () => setShowPassword((show) => !show),
    []
  );
  const handleMouseDownPassword = useCallback(
    (event) => event.preventDefault(),
    []
  );

  // >>>>>>>>>>>>>>>>>>>>>
  const isAuth = useSelector((state) => state.user.isAuthenticated);
  useEffect(() => {
    // Redirect to dashboard if already signed in
    if (isAuth) {
      navigate("/account");
    }
  }, [isAuth, navigate]);

  // if (LoginByGoogleResult.isLoading) return <Loader />;

  return (
    <section className="flex justify-center items-center">
      <GoogleOAuthProvider clientId={googleClientID}>
        <div
          onSubmit={handleSubmit}
          className="flex items-center w-10/12 justify-center min-h-screen"
          style={{
            backgroundImage: "url('/bglogo.png')",
            // backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="w-full max-w-xl px-8 py-10 rounded-lg relative text-center">
            <h1 className="text-5xl font-bold text-primary mb-5">Hi there!</h1>
            <p className="text-[#1B1B1B] text-2xl mb-9">
              Welcome to <span className="font-bold text-primary">ACT</span>
            </p>

            {/* Google Login Button */}
            <GoogleLoginBtn />
            {/* <GoogleLogin
              onSuccess={handleSuccessGoogleLogin}
              onFailure={handleFailureGoogleLogin}
              select_account={false}
              text="continue_with"
            >
              <button className="w-full bg-[#F1F1F1] rounded-[14px] flex items-center justify-center py-3 border border-[#B1B1B1] text-[#1B1B1B] hover:bg-gray-100">
                <img
                  src="/icons/Google.png"
                  alt="google"
                  className="mr-2 text-xl"
                />
                Continue with Google
              </button>
            </GoogleLogin> */}

            <div className="my-9 flex justify-center items-center gap-2">
              <div className="w-16 bg-[#B1B1B1] h-[1px]"></div>
              <p className="text-[#1B1B1B] text-lg">or</p>
              <div className="w-16 bg-[#B1B1B1] h-[1px]"></div>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="mb-9">
                <input
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="email"
                  placeholder="Email"
                  className={`w-full px-4 py-3 border rounded-[14px] ${
                    errors.email && touched.email
                      ? "border-red-500"
                      : "border-[#B1B1B1]"
                  } focus:outline-none focus:ring-2 focus:ring-[#1F2977]`}
                />
                {errors.email && touched.email && (
                  <p className="text-red-500 text-start text-sm mt-1">
                    email is {errors.email}
                  </p>
                )}
              </div>

              {/* Password Input */}
              <div className="mb-2">
                <input
                  id="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  className={`w-full px-4 py-3 border rounded-[14px] ${
                    errors.password && touched.password
                      ? "border-red-500"
                      : "border-[#B1B1B1]"
                  } focus:outline-none focus:ring-2 focus:ring-[#1F2977]`}
                />
                {errors.password && touched.password && (
                  <p className="text-red-500 text-start text-sm mt-1">
                    password is {errors.password}
                  </p>
                )}
              </div>

              {/* Forgot Password */}
              <div className="text-right mb-5">
                <Link to="#" className="text-[#0061FF] text-xs hover:underline">
                  Forgot Password?
                </Link>
              </div>

              {/* Login Button */}
              <button
                type="submit"
                className="w-full py-3 rounded-[27px] bg-primary text-white text-lg font-semibold hover:bg-[#131a5e]"
              >
                Login
              </button>
            </form>

            {/* Sign Up Link */}
            <p className="mt-4 text-gray-500 text-sm">
              Don’t have an account?{" "}
              <Link
                to="/signup"
                className="text-[#1F2977] font-medium hover:underline"
              >
                Sign up
              </Link>
            </p>
          </div>
        </div>
      </GoogleOAuthProvider>
    </section>
  );
}
export default LoginPage;

// <GoogleOAuthProvider clientId={googleClientID}>
//   <Box
//     sx={{
//       width: "80%",
//       typography: "body1",
//       display: "flex",
//       flexDirection: "column",
//       alignItems: "center",
//       justifyContent: "center",
//       margin: "auto",
//       minHeight: "100vh"
//     }}
//   >
//     <TabContext value={value}>
//       <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
//         <TabList onChange={handleChange} aria-label="login and signin">
//           <Tab label="Login" value="1" />
//           <Tab label="Signup" value="2" />
//         </TabList>
//       </Box>
//       <TabPanel value="1">
//         <LoginForm />
//       </TabPanel>
//       <TabPanel value="2">
//         <SignupForm />
//       </TabPanel>
//     </TabContext>
//   </Box>
// </GoogleOAuthProvider>
