import React from "react";

const Services = () => {
  const services = [
    { icon: "/icons/services/computer.png", title: "Online Portal" },
    { icon: "/icons/services/computerMoney.png", title: "Donation Links" },
    { icon: "/icons/services/phone.png", title: "Donate by Phone" },
    { icon: "/icons/services/phone2.png", title: "Donate by Phone" },
    {
      icon: "/icons/services/pauseMechine.png",
      title: "Credit Card Terminal Here",
    },
    { icon: "/icons/services/target.png", title: "Base Charity for Campaigns" },
    {
      icon: "/icons/services/message.png",
      title: "Balance and Activity Text Back",
    },
    {
      icon: "/icons/services/daial.png",
      title: "Fully Automated Phone System",
    },
    {
      icon: "/icons/services/bank.png",
      title: "Grant Distribution to Bank & Card",
    },
    {
      icon: "/icons/services/org.png",
      title: "Organisation Distribution Management",
    },
    {
      icon: "/icons/services/global.png",
      title: "Send to Charities in the UK & Abroad",
    },
    { icon: "/icons/services/moneyHand.png", title: "Plus Many More Services" },
  ];
  return (
    <section className="bg-primary py-[74px] px-[86px]">
      <div>
        <div className="grid grid-cols-1 gap-8 xl:gap-x-40 xl:gap-y-[120px] sm:grid-cols-2 lg:grid-cols-4 text-center">
          {services.map((service, index) => (
            <div
              key={Math.random()}
              className="flex flex-col items-center cursor-pointer text-white space-y-5 hover:scale-105 active:scale-95 transition-transform duration-200 ease-in-out"
            >
              <img
                className="w-36 h-32"
                src={service.icon}
                alt={service.icon}
              />
              <p className="text-2xl font-bold">{service.title}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
