import React from "react";
import ExploreButton from "../../button/ExploreButton";

const MissionVisionValues = () => {
  return (
    <section className="px-6 md:px-16 flex flex-col gap-[74px] lg:px-12 py-16">
      <div className="flex justify-center">
        <div className="text-center w-full lg:w-4/5 flex flex-col items-center gap-4">
          <h2 className="text-xl md:text-2xl lg:text-4xl font-bold text-primary ">
            Join hands with ACT to supercharge your donations and fundraising.
          </h2>
          <p className="text-lg lg:text-xl w-10/12 lg:w-9/12 text-baseDark">
            Our extensive range of services provides you with the tools and
            support you need to streamline your donations and maximise your
            impact.
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 xl:gap-[75px]">
        {/* Our Mission */}
        <div className="text-justify py-5 pr-3 h-[342px] border-b-2 md:border-b-0 md:border-r-2 border-borderColor">
          <h3 className="text-6xl font-bold text-primary mb-[52px]">
            <span className="relative inline-block">
              <span className="absolute inset-0 bg-teal-100 -z-10 -skew-y-2"></span>
              Our Mission
            </span>
          </h3>
          <p className="text-lg md:text-xl text-baseDark mb-[77px]">
            We aim to empower individuals and organisations by providing
            innovative solutions for fundraising and donations.
          </p>
          <ExploreButton label="Explore More" textColor="text-primary" />
        </div>

        {/* Our Vision */}
        <div className="text-justify pr-3 py-5 h-[342px] border-b-2 md:border-b-0 md:border-r-2 border-borderColor">
          <h3 className="text-6xl font-bold text-primary mb-[52px]">
            <span className="relative inline-block">
              <span className="absolute inset-0 bg-teal-100 -z-10 -skew-y-2"></span>
              Our Vision
            </span>
          </h3>
          <p className="text-lg md:text-xl text-baseDark mb-[77px]">
            A world where every donation and fundraising effort is maximised for
            the greatest impact.
          </p>
          <ExploreButton label="Explore More" textColor="text-primary" />
        </div>

        {/* Our Values */}
        <div className="text-justify py-5 h-[342px]">
          <h3 className="text-6xl font-bold text-primary mb-[52px]">
            <span className="relative inline-block">
              <span className="absolute inset-0 bg-teal-100 -z-10 -skew-y-2"></span>
              Our Values
            </span>
          </h3>
          <p className="text-lg md:text-xl text-baseDark mb-[77px]">
            Transparency, integrity, and innovation are at the heart of
            everything we do.
          </p>
          <ExploreButton label="Explore More" textColor="text-primary" />
        </div>
      </div>
    </section>
  );
};

export default MissionVisionValues;
