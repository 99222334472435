import React, { useState } from "react";
import "./css/requestForm.css";
import { useInterestedToOpenAccountMutation } from "../../../store/apis/supportAPI";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { interestedToOpenAccountSchema } from "../../../Validations/contactFormValidations";
import { Link } from "react-router-dom";
import PrimaryInput from "../../inputfield/PrimaryInput";

const RequestForm = () => {
  const [accountType, setAccountType] = useState("donor-account");
  const [interestedToOpenAccount] = useInterestedToOpenAccountMutation();
  //handlers
  const onSubmit = async (
    { firstName, lastName, phoneNumber, email },
    actions
  ) => {
    const data = {
      accountType,
      firstName,
      lastName,
      phoneNumber,
      email,
    };
    // const res = await interestedToOpenAccount(data);
    await interestedToOpenAccount(data);

    // if (res.error) {
    //   toast.error("Faild to send: " + res.error.data.message);
    // }
    toast.success(
      "We will get in touch with you shortly to complete the account opening process."
    );
    actions.resetForm();
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        accountType: "",
        firstName: "",
        lastName: "",
        charityName: "",
        phoneNumber: "",
        email: "",
      },
      validationSchema: interestedToOpenAccountSchema,
      onSubmit,
    });

  return (
    <section className="bg-blue-50 py-12 px-6 h-[120vh] flex justify-center items-center">
      <div className="w-full grid grid-cols-1 md:grid-cols-2 items-center">
        {/* Left Section */}
        <div className="text-left px-12 bg-lightB h-full flex flex-col justify-center">
          <h2 className="text-4xl font-bold text-primary mb-5">
            Open your A.C.T. account today
          </h2>
          <ol className="list-decimal list-inside space-y-8 text-primary text-lg">
            <li className="flex gap-1">
              Fill in your details on the right.{" "}
              <div className="w-7 h-7 rounded-full bg-lGree flex justify-center items-center">
                <img src="/icons/DownArrow.png" alt="" />
              </div>
            </li>
            <li>Our team will email you the applicable form.</li>
            <li>
              Fill in the online form & your account ID will be emailed to you.
            </li>
            <li>Start Raising Funds</li>
          </ol>
          <p className="mt-8 text-primary text-2xl">
            For any assistance please call or email us.{" "}
            <span className="font-bold">We are happy to help</span>
          </p>
          <p className="mt-8 text-primary text-lg">
            Have an account?{" "}
            <a href="/login" className="text-xl font-semibold">
              (Login now)
            </a>
          </p>
        </div>

        {/* Right Section */}
        <div className="bg-primary text-white p-8 ">
          <h3 className="text-4xl font-bold mb-8">
            I would Like To open a....
          </h3>
          <form onSubmit={handleSubmit} className="flex flex-col gap-6">
            <div>
              <select
                name="accountType"
                className="w-full p-3 border cursor-pointer text-white bg-transparent border-gray-300 rounded focus:outline-none focus:bg-primary focus:ring-2 focus:ring-blue-400"
                value={accountType}
                onChange={(e) => setAccountType(e.target.value)}
              >
                <option value="Donor account" className="cursor-pointer">
                  Donor account
                </option>
                <option value="Recipient account" className="cursor-pointer">
                  Recipient account
                </option>
              </select>
              {/* Validation error for accountType */}
              {errors.accountType && touched.accountType && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.accountType}
                </p>
              )}
            </div>

            {/* First Name */}
            <div>
              <PrimaryInput
                value={values.firstName}
                handleBlur={handleBlur}
                handleChange={handleChange}
                label="First Name"
                name="firstName"
                placeholder="First Name"
              />
              {errors.firstName && touched.firstName && (
                <p className="text-red-500 text-sm mt-1">{errors.firstName}</p>
              )}
            </div>

            {/* Last Name */}
            <div>
              <PrimaryInput
                value={values.lastName}
                handleBlur={handleBlur}
                handleChange={handleChange}
                label="Last Name"
                name="lastName"
                placeholder="Last Name"
              />
              {errors.lastName && touched.lastName && (
                <p className="text-red-500 text-sm mt-1">{errors.lastName}</p>
              )}
            </div>

            {/* Phone Number */}
            <div>
              <PrimaryInput
                value={values.phoneNumber}
                handleBlur={handleBlur}
                handleChange={handleChange}
                label="Mobile Number"
                name="phoneNumber"
                placeholder="Mobile Number"
              />
              {errors.phoneNumber && touched.phoneNumber && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.phoneNumber}
                </p>
              )}
            </div>

            {/* Email */}
            <div>
              <PrimaryInput
                value={values.email}
                handleBlur={handleBlur}
                handleChange={handleChange}
                label="Email Address"
                type="email"
                name="email"
                placeholder="Email Address"
              />
              {errors.email && touched.email && (
                <p className="text-red-500 text-sm mt-1">{errors.email}</p>
              )}
            </div>

            <button
              type="submit"
              className="w-full py-3 text-xl bg-white text-primary font-bold rounded hover:bg-gray-200 hover:scale-105 active:scale-95 transition"
            >
              SUBMIT
            </button>
          </form>
        </div>

        {/* <div className="bg-primary text-white p-8 ">
          <h3 className="text-4xl font-bold mb-8">
            I would Like To open a....
          </h3>
          <form onSubmit={handleSubmit} className="flex flex-col gap-10">
            <div>
              <select
                name="accountType"
                className="w-full p-3 border cursor-pointer text-white bg-transparent border-gray-300 rounded focus:outline-none focus:bg-primary focus:ring-2 focus:ring-blue-400"
                value={accountType}
                onChange={(e) => setAccountType(e.target.value)}
              >
                <option value="Donor account" className="cursor-pointer">
                  Donor account
                </option>
                <option value="Recipient account" className="cursor-pointer">
                  Recipient account
                </option>
              </select>
            </div>
            <PrimaryInput
              value={values.firstName}
              handleBlur={handleBlur}
              handleChange={handleChange}
              label="First Name"
              name="firstName"
              placeholder="First Name"
            />
            <PrimaryInput
              value={values.lastName}
              handleBlur={handleBlur}
              handleChange={handleChange}
              label="Last Name"
              name="lastName"
              placeholder="Last Name"
            />
            <PrimaryInput
              value={values.phoneNumber}
              handleBlur={handleBlur}
              handleChange={handleChange}
              label="Mobile Number"
              name="phoneNumber"
              placeholder="Mobile Number"
            />
            <PrimaryInput
              value={values.email}
              handleBlur={handleBlur}
              handleChange={handleChange}
              label="Email Address"
              type="email"
              name="email"
              placeholder="Email Address"
            />
            <button
              type="submit"
              className="w-full py-3 text-xl bg-white text-primary font-bold rounded hover:bg-gray-200 hover:scale-105 active:scale-95 transition"
            >
              SUBMIT
            </button>
          </form>
        </div> */}
      </div>
    </section>
  );
};

export default RequestForm;
