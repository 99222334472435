import React from "react";
import ExploreButton from "../../button/ExploreButton";

const FundraisingPower = () => {
  return (
    <section className="h-[60vh] flex text-center">
      <div className="flex-1 flex justify-center items-center text-primary">
        <div className="flex flex-col gap-9 w-[510px]">
          <h1 className="text-6xl font-bold">Fundraising power</h1>
          <p className="text-xl">
            ACT maximises fundraising power by offering a robust platform that
            reaches donors through multiple channels.
          </p>
          <ExploreButton label="Explore More" />
        </div>
      </div>
      <div className="flex-1 flex justify-center items-center text-white bg-dPrimary">
        <div className="flex flex-col gap-9 w-[510px]">
          <h1 className="text-6xl font-bold">Donors flexibility</h1>
          <p className="text-xl">
            ACT makes it easy for donors to support causes they care about,
            wherever and whenever it suits them best.
          </p>
          <ExploreButton label="Explore More" textColor="text-white" />
        </div>
      </div>
    </section>
  );
};

export default FundraisingPower;
