import React, { useState } from "react";
import "../components/features/Dashboard/css/requestCard.css";
import { useSelector } from "react-redux";
import { useUpdateClientMutation, useFetchUsersClientsQuery } from "../store";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const RequestCard = () => {
  const [inputValue, setInputValue] = useState("");
  const selectedClient = useSelector((state) => state.user.selectedClient);
  const client = useSelector((state) => state.user.clients[selectedClient]);
  const token = useSelector((state) => state.user.token);
  const [requestCard] = useUpdateClientMutation();
  const navigate = useNavigate();

  const { data } = useFetchUsersClientsQuery(token);

  //on click handlers
  const handleChangeDefaultPaymentMethod = async (e) => {
    e.preventDefault();
    setInputValue("");
    try {
      const res = await requestCard({
        clientID: client?.ID,
        field: "CardStatus",
        value: "Need To Order Card",
        token,
      }).unwrap();
      toast.success(res.message);
      navigate("/account");
    } catch (error) {
      toast.error(
        error.data?.message ||
          "An error occurred while processing your request."
      );
    }
  };

  const handleInput = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <div></div>
    // <section className="main-container">
    //   <div className="container">
    //     <h2 className="title">Request for Prepaid Card</h2>
    //     <form className="form" onSubmit={handleChangeDefaultPaymentMethod}>
    //       <div className="form-group">
    //         <label htmlFor="reason">Reason for Request</label>
    //         <textarea id="reason" name="reason" rows="4" value={inputValue} onChange={handleInput} disabled></textarea>
    //       </div>
    //       <button type="submit" className="submit-button">
    //         Submit Request
    //       </button>
    //     </form>
    //   </div>
    // </section>
  );
};

export default RequestCard;
